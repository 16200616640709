import React from 'react';
import logo from './logo.svg';
import './App.css';
import { ErrorBoundary } from 'react-error-boundary';
import { Navigate, useNavigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Home from './pages/home';
import Dashboard from './pages/dashboard';
import Pricing from './pages/pricing';
import Privacy from './pages/privacy';
import Terms from './pages/terms';
import AboutUs from './pages/aboutus';
import DataProcessing from './pages/data-processing-agreement';
import Services from './pages/service-level-agreement';


function App() {
  
  if (process.env.REACT_APP_MAINTENANCE_MODE === 'true') {
    return(
    <div className="App">Under Maintenance</div>
    )
  }
  else {

    return (
      <div className="App">
        
        <Router>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="*" element={<Navigate to="/" />} />
            <Route path="/dashboard/:active_tab?" element={<Dashboard />} />
            <Route exact path="/pricing" element={<Pricing />} />
            <Route exact path="/privacy" element={<Privacy />} />
            <Route exact path="/terms" element={<Terms />} />
            <Route exact path="/aboutus" element={<AboutUs />} />
            <Route exact path="/service-level-agreement" element={<Services />} />
            <Route exact path="/data-processing-agreement" element={<DataProcessing />} />
          </Routes>
        </Router>
      </div>
    );

  }
}

export default App;
