import React from "react";
import Layout from "../layouts";

const DataProcessing = () => {

    return (
        <>
            <Layout>
                <div className="pricing-header row p-3 pb-md-4 mx-auto mb-5">
                    <div className="col-12 mx-auto text-left mt-1">
                        <p className="fs-3">Data Processing Agreement</p>
                        <p className="fs-6 text-muted">
                            <div class="fl-rich-text">
                               
                                <p class="p1"><b>Overview</b></p>
                                <p class="p2">This Data Processing Agreement (“DPA”) reflects the requirements of the European Data Protection Regulation (“GDPR”). SingleClickShare´s services (“Services”) offered in the European Union are GDPR ready and this DPA provides you as a customer ("Customer") with the necessary documentation on this readiness. The DPA is an addendum to the Terms of Service (“Terms”) between SingleClickShare, (“SingleClickShare”) and the Customer. All capitalized terms not defined in this DPA shall have the meanings set forth in the Terms. Customer enters into this DPA on behalf of itself and, to the extent required under Data Protection Laws, in the name and on behalf of its Authorized Affiliates (defined below).</p>
                                <p>The parties agree as follows:</p>
                                <p class="p3"><b>1. Definitions</b></p>
                                <p class="p2">1.1. “Affiliate” means an entity that directly or indirectly Controls, is Controlled by or is under common Control with an entity.</p>
                                <p>1.2. “Alternative Transfer Solution” means a solution, other than the Standard Contractual Clauses, that enables the lawful transfer of personal data to a third country in accordance with Data Protection Law.</p>
                                <p>1.3. “Authorized Affiliate” means any of Customer Affiliate(s) permitted to or otherwise receiving the benefit of the Services pursuant to the Terms.</p>
                                <p>1.4. “Control” means an ownership, voting or similar interest representing fifty percent (50%) or more of the total interests then outstanding of the entity in question. The term “Controlled” shall be construed accordingly.</p>
                                <p>1.5. “Controller” means an entity that determines the purposes and means of the processing of Personal Data.</p>
                                <p>1.6. “Customer Data” means any data that SingleClickShare and/or its Affiliates processes on behalf of Customer in the course of providing the Services under the Terms.</p>
                                <p>1.7. “Data Protection Laws” means all data protection and privacy laws and regulations applicable to the processing of Personal Data under the Terms, including, where applicable, Regulation 2016/679 of the European Parliament and of the Council on the protection of natural persons with regard to the processing of Personal Data and on the free movement of such data (General Data Protection Regulation) (“GDPR”); (ii) Directive 2002/58/EC concerning the processing of Personal Data and the protection of privacy in the electronic communications sector and applicable national implementations of it (in each case, as may be amended, superseded or replaced); (iii) the Federal Data Protection Act of 19 June 1992 (Switzerland); (iv) EU or EU Member State law; (v) the law of the UK or a part of the UK; and the GDPR as amended and incorporated into UK law under the UK European Union (Withdrawal) Act 2018, if in force.</p>
                                <p>1.8. “Personal Data” means any Customer Data relating to an identified or identifiable natural person to the extent that such information is protected as personal data under applicable Data Protection Law.</p>
                                <p>1.9. "Standard Contractual Clauses" mean standard data protection clauses for the transfer of personal data to processors established in third countries which do not ensure an adequate level of data protection, as described in Article 46 of the EU GDPR and set out at Annex.</p>
                                <p>1.10. “Processor” means an entity that processes Personal Data on behalf of the Controller.</p>
                                <p>1.11. “Processing” has the meaning given to it in the GDPR and “process”, “processes” and “processed” shall be interpreted accordingly.</p>
                                <p>1.12. “Security Incident” means any unauthorized or unlawful breach of security that leads to the accidental or unlawful destruction, loss, alteration, unauthorized disclosure of or access to Personal Data.</p>
                                <p>1.13. “Services” means any product or service provided by SingleClickShare to Customer pursuant to and as more particularly described in the Terms.</p>
                                <p>1.14. “Sub-processor” means any Processor engaged by SingleClickShare or its Affiliates to assist in fulfilling its obligations with respect to providing the Services pursuant to the Terms or this DPA. Sub-processors may include third parties or any SingleClickShare Affiliate.</p>
                                <p class="p3"><b>2. Scope and Applicability of this DPA</b></p>
                                <p class="p3"><b>2.1 Application</b></p>
                                <p class="p2">This DPA applies where and only to the extent that SingleClickShare processes Personal Data on behalf of the Customer in the course of providing the Services and such Personal Data is subject to Data Protection Laws of the European Union, the European Economic Area and/or their member states, Switzerland and/or the United Kingdom. The parties agree to comply with the terms and conditions in this DPA in connection with such Personal Data.</p>
                                <p class="p3"><b>2.2 Role of the Parties</b></p>
                                <p class="p2">As between SingleClickShare and Customer, Customer is the Controller of Personal Data and SingleClickShare shall process Personal Data only as a Processor on behalf of Customer. Nothing in the Terms or this DPA shall prevent SingleClickShare from using or sharing any data that SingleClickShare would otherwise collect and process independently of Customer’s use of the Services.</p>
                                <p class="p3"><b>2.3 Customer Obligations</b></p>
                                <p class="p2">Customer agrees that (i) it shall comply with its obligations as a Controller under Data Protection Laws in respect of its processing of Personal Data and any processing instructions it issues to SingleClickShare; and (ii) it has provided notice and obtained (or shall obtain) all consents and rights necessary under Data Protection Laws for SingleClickShare to process Personal Data and provide the Services pursuant to the Terms and this DPA.</p>
                                <p class="p3"><b>2.4 SingleClickShare Processing of Personal Data</b></p>
                                <p class="p2">As a Processor, SingleClickShare shall process Personal Data only for the following purposes: (i) processing to perform the Services in accordance with the Terms; (ii) processing to perform any steps necessary for the performance of the Terms; and (iii) to comply with other reasonable instructions provided by Customer to the extent they are consistent with the terms of this Terms and only in accordance with Customer’s documented lawful instructions. The parties agree that this DPA and the Terms set out the Customer’s complete and final instructions to SingleClickShare in relation to the processing of Personal Data and processing outside the scope of these instructions (if any) shall require prior written agreement between Customer and SingleClickShare.</p>
                                <p class="p3"><b>2.5 Nature of the Data</b></p>
                                <p class="p2">SingleClickShare handles Customer Data provided by Customer. Such Customer Data may contain e-mail addresses, social media postings, social media analytics and/or social media authorization tokens depending on how the Services are used by Customer. The Customer Data may be subject to the following process activities: (i) storage and other processing necessary to provide, maintain and improve the Services provided to Customer; (ii) to provide customer and technical support to Customer; and (iii) disclosures as required by law or otherwise set forth in the Terms.</p>
                                <p class="p3"><b>2.6 SingleClickShare Data</b></p>
                                <p class="p2">Notwithstanding anything to the contrary in the Terms (including this DPA), Customer acknowledges that SingleClickShare shall have a right to use and disclose data relating to and/or obtained in connection with the operation, support and/or use of the Services for its legitimate business purposes, such as billing, account management, technical support, product development and sales and marketing. To the extent any such data is considered personal data under Data Protection Laws, SingleClickShare is the Controller of such data and accordingly shall process such data in compliance with Data Protection Laws.</p>
                                <p class="p3"><b>3. Sub-processing</b></p>
                                <p class="p2">SingleClickShare uses certain third party sub-processors, subcontractors and content delivery networks to assist it in providing the Services as described in the Terms.</p>
                                <p>Prior to engaging any third party sub-processor, SingleClickShare performs reasonable diligence to evaluate their privacy, security and confidentiality practices, and executes an agreement implementing its applicable obligations.</p>
                                <p class="p3"><b>3.1 Authorized Sub-processors</b></p>
                                <p class="p4">Customer agrees that SingleClickShare may engage Sub-processors to process Personal Data on Customer’s behalf. The Sub-processors currently engaged by SingleClickShare and authorized by Customer are:</p>
                                <p>1. Google LLC, United States<br/>
                                    2. Mailgun Technologies, United States<br/>
                                        3. Cloudflare, Inc, United States<br/>
                                            4. Stripe, Inc, United States<br/>
                                                5. Mailchimp (Intuit Inc.), United States<br/>
                                                    </p>
                                                    <p>If Customer has entered into the Standard Contractual Clauses, the above authorizations constitute Customer’s prior written consent to the subcontracting by SingleClickShare of the processing of Customer Data.</p>
                                                    <p class="p3"><b>3.2 Sub-processor Obligations</b></p>
                                                    <p class="p2">SingleClickShare shall: (i) enter into a written agreement with the Sub-processor imposing data protection terms that require the Sub-processor to protect the Personal Data to the standard required by Data Protection Laws; and (ii) remain responsible for its compliance with the obligations of this DPA and for any acts or omissions of the Sub-processor that cause SingleClickShare to breach any of its obligations under this DPA.</p>
                                                    <p>The Sub-processor only accesses and uses Customer Data to the extent required to perform the obligations subcontracted to it, and does so in accordance with the DPA and the Standard Contractual Clauses or Alternative Transfer Solution; and if the GDPR applies to the processing of Customer Personal Data, the data protection obligations described in Article 28(3) of the GDPR, as described in the DPA, are imposed on the Subprocessor.</p>
                                                    <p class="p3"><b>3.3 Changes to Sub-processors</b></p>
                                                    <p class="p2">SingleClickShare shall update this DPA if it adds or removes Sub-processors. Customers are advised to occasionally re-visit the DPA to check for changes.</p>
                                                    <p class="p3"><b>3.4 Objection to Sub-processors</b></p>
                                                    <p class="p2">Customer may object in writing to SingleClickShare’ appointment of a new Sub-processor on reasonable grounds relating to data protection by notifying SingleClickShare. Such notice shall explain the reasonable grounds for the objection. In such event, the parties shall discuss such concerns in good faith with a view to achieving a commercially reasonable resolution. If this is not possible, either party may terminate the applicable Services that cannot be provided by SingleClickShare without the use of the objected-to-new Sub-processor.</p>
                                                    <p class="p3"><b>4. Security</b></p>
                                                    <p class="p3"><b>4.1 Security Measures</b></p>
                                                    <p class="p2">SingleClickShare shall implement and maintain commercially reasonable appropriate technical and organizational security measures to protect Personal Data from Security Incidents and to preserve the security and confidentiality of the Personal Data, in accordance with SingleClickShare’s security standards<span class="Apple-converted-space">&nbsp;</span></p>
                                                    <p class="p3"><b>4.2 Confidentiality of Processing</b></p>
                                                    <p class="p2">SingleClickShare shall commercially reasonable ensure that any person who is authorized by SingleClickShare to process Personal Data (including its staff, agents and sub-contractors) shall be under an appropriate obligation of confidentiality (whether a contractual or statutory duty).</p>
                                                    <p class="p3"><b>4.3 Security Incident Response</b></p>
                                                    <p class="p2">Upon confirmation of a confirmed breach, SingleClickShare shall notify Customer without undue delay and shall provide timely information relating to the Security Incident as it becomes known or as is reasonably requested by Customer.</p>
                                                    <p class="p3"><b>4.4 Updates to Security Measures</b></p>
                                                    <p class="p2">Customer acknowledges that the Security Measures are subject to technical progress and development and that SingleClickShare may update or modify the Security Measures from time to time provided that such updates and modifications do not result in the degradation of the overall security of the Services purchased by the Customer.</p>
                                                    <p class="p3"><b>5. Security Reports and Audits</b></p>
                                                    <p class="p3"><b>5.1. Security Documentation</b></p>
                                                    <p class="p2">SingleClickShare shall maintain records of its security standards. Upon Customer’s written request, SingleClickShare shall provide (on a confidential basis) copies of relevant external certifications, audit report summaries and/or other documentation reasonably required by Customer to verify SingleClickShare’s compliance with this DPA. SingleClickShare shall further provide written responses (on a confidential basis) to all reasonable requests for information made by Customer, including responses to information security and audit questionnaires, that Customer (acting reasonably) considers necessary to confirm SingleClickShare’s compliance with this DPA, provided that Customer shall not exercise this right more than once per year.</p>
                                                    <p class="p3"><b>5.2.</b></p>
                                                    <p class="p2">If Customer has entered into the Standard Contractual Clauses, SingleClickShare will allow Customer or an independent auditor appointed by Customer to conduct audits as described in the Standard Contractual Clauses</p>
                                                    <p class="p3"><b>6. International Transfers</b></p>
                                                    <p class="p3"><b>6.1 Processing Locations</b></p>
                                                    <p class="p2">SingleClickShare stores and processes Personal Data under this DPA from the European Union, the European Economic Area and/or their member states and Switzerland (“EU Data”) in data centers located in United States and therefore outside the European Union. SingleClickShare shall implement appropriate safeguards to protect the Personal Data, wherever it is processed, in accordance with the requirements of Data Protection Laws.</p>
                                                    <p class="p3"><b>6.2 Transfers of Data</b></p>
                                                    <p class="p2">If the storage and/or processing of Customer Personal Data involves transfers of Customer Personal Data from the EEA, Switzerland or the UK to any third country that does not ensure an adequate level of protection under Data Protection Laws, and Data Protection Laws apply to those transfers, then the transfers will be subject to the Standard Contractual Clauses; and SingleClickShare will ensure that it complies with its obligations under the Standard Contractual Clauses in respect of those transfers. If Customer does not enter into the Standard Contractual Clauses, Customer shall apply an Alternative Transfer Solution and notify SingleClickShare about it.</p>
                                                    <p class="p3"><b>6.1 Disclosure of Confidential Information Containing Personal Data</b></p>
                                                    <p class="p2">If Customer has entered into the Standard Contractual Clauses, SingleClickShare will, notwithstanding any term to the contrary in the DPA, ensure that any disclosure of Customer’s Confidential Information containing personal data, and any notifications relating to any such disclosures, will be made in accordance with such Standard Contractual Clauses.</p>
                                                    <p class="p3"><b>7. Return or Deletion of Data</b></p>
                                                    <p class="p3"><b>7.1 Data Retention</b></p>
                                                    <p class="p2">Upon deactivation of the Services, all Personal Data shall be deleted, save that this requirement shall not apply to the extent SingleClickShare is required by applicable law to retain some or all of the Personal Data, or to Personal Data it has archived on back-up systems, which such Personal Data SingleClickShare shall securely isolate and protect from any further processing, except to the extent required by applicable law.</p>
                                                    <p class="p3"><b>8. Cooperation</b></p>
                                                    <p class="p3"><b>8.1 Reasonable Cooperation</b></p>
                                                    <p class="p2">To the extent that Customer is unable to independently access the relevant Personal Data within the Services, SingleClickShare shall (at Customer’s expense) taking into account the nature of the processing, provide reasonable cooperation to assist Customer by appropriate technical and organizational measures, in so far as is possible, to respond to any requests from individuals or applicable data protection authorities relating to the processing of Personal Data under the Terms. In the event that any such request is made directly to SingleClickShare, SingleClickShare shall not respond to such communication directly without Customer’s prior authorization, unless legally compelled to do so. If SingleClickShare is required to respond to such a request, SingleClickShare shall promptly notify Customer and provide it with a copy of the request unless legally prohibited from doing so.</p>
                                                    <p class="p3"><b>8.2 Data Protection Authority</b></p>
                                                    <p class="p2">To the extent SingleClickShare is required under Data Protection Law, SingleClickShare shall (at Customer’s expense) provide reasonably requested information regarding SingleClickShare’s processing of Personal Data under the Terms to enable the Customer to carry out data protection impact assessments or prior consultations with data protection authorities as required by law.</p>
                                                    <p class="p3"><b>9. Miscellaneous</b></p>
                                                    <p class="p3"><b>9.1 Terms of Service Prevalence</b></p>
                                                    <p class="p2">Except for the changes made by this DPA, the Terms remains unchanged and in full force and effect. If there is any conflict between this DPA and the Terms, this DPA shall prevail to the extent of that conflict.</p>
                                                    <p class="p3"><b>9.2 Integral to Terms</b></p>
                                                    <p class="p2">This DPA is a part of and incorporated into the Terms so references to “Terms” in the Terms shall include this DPA.</p>
                                                    <p class="p3"><b>9.3 Liability</b></p>
                                                    <p class="p2">In no event shall any party limit its liability with respect to any individual’s data protection rights under this DPA or otherwise.</p>
                                                    <p class="p3"><b>9.4 Governing</b></p>
                                                    <p class="p4">This DPA shall be governed by and construed in accordance with governing law and jurisdiction provisionsof the city of New York, USA, unless required otherwise by Data Protection Laws.</p>
                                                    <p>&nbsp;</p>
                                                </div>
                                            </p>
                                        </div>
                                    </div>
    </Layout>
    </>
    )
}

export default DataProcessing