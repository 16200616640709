import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";


const Header = () => {
  const navigator = useNavigate();
  const gotoHome = ()=>{
    navigator("/");
  }
  const signout = ()=>{
    localStorage.removeItem("authToken");
    localStorage.removeItem("email");
    localStorage.removeItem("__uid");   
    localStorage.removeItem("name");
    navigator("/");
  }
  return (
    <>
      <header class="navbar navbar-dark sticky-top flex-md-nowrap p-0 shadow admin-header-bg">
            <a class="navbar-brand col-md-3 col-lg-2 me-0 px-3" href="#" onClick={gotoHome}>
              <img width="60%"
                src="/assets/images/single-click-share-logo.png" /></a>
            <button class="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
        
            <div class="navbar-nav">
              <div class="nav-item text-nowrap">
                <a class="nav-link px-3" href="#" onClick={signout}>{(localStorage.getItem("authToken"))? "Sign out":null}</a>
              </div>
            </div>
          </header>          
          
        </>
  );
};

export default Header;
