import React from "react";
import Layout from "../layouts";

const Terms = () => {

    return (
        <>
            <Layout>
                <div className="pricing-header row p-3 pb-md-4 mx-auto mb-5">
                    <div className="col-12 mx-auto text-left mt-1">
                        <p className="fs-3">Term of Use</p>
                        <p className="fs-6 text-muted">
                            <div className="fl-rich-text">
                                
                                <p className="c4"><span className="c8">The website located at <a href="https://singleclickshare.com">singleclickshare.com </a></span><span className="c1">(collectively the “Site”) is a copyrighted work belonging to SingleClicShare (“Company”, “us”, “our”, and “we”). &nbsp;Certain features of the Site may be subject to additional guidelines, terms, or rules, which will be posted on the Site in connection with such features. &nbsp;All such additional terms, guidelines, and rules are incorporated by reference into these Terms.</span></p>
                                <p className="c4"><span className="c1">These Terms of Use (these “Terms”) set forth the legally binding terms and conditions that govern your use of the Site. &nbsp;By accessing or using the Site, you are accepting these Terms (on behalf of yourself or the entity that you represent), and you represent and warrant that you have the right, authority, and capacity to enter into these Terms (on behalf of yourself or the entity that you represent). &nbsp;you may not access or use the Site or accept the Terms if you are not at least 18 years old. If you do not agree with all of the provisions of these Terms, do not access and/or use the Site.</span></p>
                                <p className="c4"><strong><span className="c3">Accounts</span></strong></p>
                                <ol className="c6 lst-kix_6efudc7sx9f1-0 start" start="1">
                                    <li className="c4 c7"><span className="c1">Account Creation. In order to use certain features of the Site, you must register for an account (“Account”) and provide certain information about yourself as prompted by the account registration form. &nbsp;You represent and warrant that: (a) all required registration information you submit is truthful and accurate; (b) you will maintain the accuracy of such information. You may delete your Account at any time, for any reason, by following the instructions on the Site.&nbsp;&nbsp;</span></li>
                                    <li className="c4 c7"><span className="c1">Account Responsibilities. You are responsible for maintaining the confidentiality of your Account login information and are fully responsible for all activities that occur under your Account. &nbsp;You agree to immediately notify Company of any unauthorized use, or suspected unauthorized use of your Account or any other breach of security. Company cannot and will not be liable for any loss or damage arising from your failure to comply with the above requirements.</span></li>
                                    <li>You are responsible for following all the terms of the platforms which you connect to your Account. You agree to be bound to all the terms of each of the platforms including the <a href="https://www.youtube.com/t/terms">YouTube Terms of Service</a>.</li>
                                    <li>Subscriptions. Some parts of the Site are billed on a subscription basis. You will be billed on a monthly or annual basis. Your subscription will automatically renew unless you cancel it or Company cancels it. You may cancel your subscription renewal by contacting the Company customer support team. You can cancel your subscription at any time so that your subscription will expire at the end of your current period. You will be charged your final subscription fee at end of the current period. There will be no future payments of subscription fees being taken, but you won’t receive a refund for payments already made.</li>
                                    <li>Subscription Fees. Company may, at any time, change the subscription fees. Any fee change will become effective at the end of the then-current billing period. Company will provide you with a reasonable prior notice of any change in fees to give you an opportunity to terminate your subscription before such change becomes effective. Your continued use of the Site after the fee change comes into effect constitutes your agreement to pay the modified fee amount.</li>
                                    <li>Fee Calculation. Company calculates user profiles for each monthly billing period using the following calculation. We calculate the total number of user profiles in your account each day. At the end of the monthly billing period, the highest daily value during the period is used as the value for the monthly billing period.</li>
                                    <li>Refunds. Refund requests may be considered by Company on a case-by-case basis and granted in the sole discretion of Company.</li>
                                    <li>Reactivation. To reactivate a suspended Site Account, you must bring your account current through the month of reactivation by making payment in full of any outstanding balance, fees and other applicable charges. In addition, we may require a reactivation fee before reactivating your account. The amount of the reactivation fee will not exceed one year of monthly fees.</li>
                                    <li>Failed Payments. If a payment fails for any reason, the Account will be suspended. If payment fails on 2 or more occasions in any twelve month period, we may require a failed payment fee on each subsequent failed payment not to exceed 10% of the failed payment charge. We reserve the right to cancel any account with a failed payment at any time.</li>
                                    <li>Late Fees. You agree to pay all fees due within 10 (10) days of the invoice date. Late payments of fees may accrue at the higher of USD $25 per month or at the rate of one and one half percent (1.5%) per month (or the highest rate permitted by applicable law, if less). SingleClickShare reserves the right to suspend or terminate your Account for failure to pay the Fees by the due date. Any outstanding Fees are immediately due and payable upon termination of the Services for any reason.</li>
                                    <li>Enterprise Plan. Customers of the Enterprise Plan have additional <a href="https://singleclickshare.com/service-level-agreement/">service level agreement</a> terms.</li>
                                </ol>
                                <p className="c4"><strong><span className="c3">Access to the Site</span></strong></p>
                                <ol className="c6 lst-kix_n6cv4mxr4jtb-0 start" start="1">
                                    <li className="c4 c7"><span className="c1">Subject to these Terms, Company grants you a non-transferable, non-exclusive, revocable, limited license to use and access the Site solely for your own personal or commercial use.</span></li>
                                    <li className="c4 c7"><span className="c1">Certain Restrictions. The rights granted to you in these Terms are subject to the following restrictions: (a) you shall not license, sell, rent, lease, transfer, assign, distribute, host, or otherwise commercially exploit the Site, whether in whole or in part, or any content displayed on the Site; (b) you shall not modify, make derivative works of, disassemble, reverse compile or reverse engineer any part of the Site; (c) you shall not access the Site in order to build a similar or competitive website, product, or service; and (d) except as expressly stated herein, no part of the Site may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means. &nbsp;Unless otherwise indicated, any future release, update, or other addition to functionality of the Site shall be subject to these Terms. All copyright and other proprietary notices on the Site (or on any content displayed on the Site) must be retained on all copies thereof.</span></li>
                                    <li>Content. You agree not to post any content to the Site that is inappropriate, illegal, false, hateful, abusive, threatening, obscene, vulgar, violent, gore, extreme, exploitative, fetish, humiliating, non-consensual, pornographic, nude, partially nude, or sexually suggestive.</li>
                                    <li className="c4 c7"><span className="c1">Company reserves the right, at any time, to modify, suspend, or discontinue the Site (in whole or in part) with or without notice to you. &nbsp;You agree that Company will not be liable to you or to any third party for any modification, suspension, or discontinuation of the Site or any part thereof.</span></li>
                                    <li className="c4 c7"><span className="c1">No Support or Maintenance. You acknowledge and agree that Company will have no obligation to provide you with any support or maintenance in connection with the Site.</span></li>
                                    <li className="c4 c7"><span className="c1">You acknowledge that all the intellectual property rights, including copyrights, patents, trademarks, and trade secrets, in the Site and its content are owned by Company or Company’s suppliers. &nbsp;Neither these Terms (nor your access to the Site) transfers to you or any third party any rights, title or interest in or to such intellectual property rights, except for the limited access rights expressly set forth in Section 2.1. Company and its suppliers reserve all rights not granted in these Terms. &nbsp;There are no implied licenses granted under these Terms.</span></li>
                                </ol>
                                <p><strong>Termination</strong></p>
                                <ol>
                                    <li>We may terminate or suspend your Account and bar access to the Site without prior notice or liability, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.</li>
                                </ol>
                                <p className="c4"><span className="c1">You agree to indemnify and hold Company (and its officers, employees, and agents) harmless, including costs and attorneys’ fees, from any claim or demand made by any third party due to or arising out of (a) your use of the Site, (b) your violation of these Terms or (c) your violation of applicable laws or regulations. &nbsp;Company reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate with our defense of these claims. You agree not to settle any matter without the prior written consent of Company. Company will use reasonable efforts to notify you of any such claim, action or proceeding upon becoming aware of it.</span></p>
                                <p className="c4"><strong><span className="c3">Third-Party Links &amp; Ads; Other Users</span></strong></p>
                                <ol className="c6 lst-kix_f5r7ku1i7nav-0 start" start="1">
                                    <li className="c4 c7"><span className="c1">Third-Party Links &amp; Ads. The Site may contain links to third-party websites and services, and/or display advertisements for third parties (collectively, “Third-Party Links &amp; Ads”). &nbsp;Such Third-Party Links &amp; Ads are not under the control of Company, and Company is not responsible for any Third-Party Links &amp; Ads. &nbsp;Company provides access to these Third-Party Links &amp; Ads only as a convenience to you, and does not review, approve, monitor, endorse, warrant, or make any representations with respect to Third-Party Links &amp; Ads. &nbsp;You use all Third-Party Links &amp; Ads at your own risk, and should apply a suitable level of caution and discretion in doing so. When you click on any of the Third-Party Links &amp; Ads, the applicable third party’s terms and policies apply, including the third party’s privacy and data gathering practices. &nbsp;You should make whatever investigation you feel necessary or appropriate before proceeding with any transaction in connection with such Third-Party Links &amp; Ads.</span></li>
                                    <li className="c4 c7"><span className="c1">You hereby release and forever discharge the Company (and our officers, employees, agents, successors, and assigns) from, and hereby waive and relinquish, each and every past, present and future dispute, claim, controversy, demand, right, obligation, liability, action and cause of action of every kind and nature (including personal injuries, death, and property damage), that has arisen or arises directly or indirectly out of, or that relates directly or indirectly to, the Site (including any interactions with, or act or omission of, other Site users or any Third-Party Links &amp; Ads). &nbsp;IF YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE CALIFORNIA CIVIL CODE SECTION 1542 IN CONNECTION WITH THE FOREGOING, WHICH STATES: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.”</span></li>
                                </ol>
                                <p className="c4"><strong><span className="c3">Disclaimers</span></strong></p>
                                <p className="c4"><span className="c1">THE SITE IS PROVIDED ON AN “AS-IS” AND “AS AVAILABLE” BASIS, AND COMPANY (AND OUR SUPPLIERS) EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING ALL WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, OR NON-INFRINGEMENT. &nbsp;WE (AND OUR SUPPLIERS) MAKE NO WARRANTY THAT THE SITE WILL MEET YOUR REQUIREMENTS, WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS, OR WILL BE ACCURATE, RELIABLE, FREE OF VIRUSES OR OTHER HARMFUL CODE, COMPLETE, LEGAL, OR SAFE. IF APPLICABLE LAW REQUIRES ANY WARRANTIES WITH RESPECT TO THE SITE, ALL SUCH WARRANTIES ARE LIMITED IN DURATION TO NINETY (90) DAYS FROM THE DATE OF FIRST USE.</span></p>
                                <p className="c4"><span className="c1">SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU. &nbsp;SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.</span></p>
                                <p className="c4"><strong><span className="c3">Limitation on Liability</span></strong></p>
                                <p className="c4"><span className="c1">TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL COMPANY (OR OUR SUPPLIERS) BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFITS, LOST DATA, COSTS OF PROCUREMENT OF SUBSTITUTE PRODUCTS, OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING FROM OR RELATING TO THESE TERMS OR YOUR USE OF, OR INABILITY TO USE, THE SITE, EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. &nbsp;ACCESS TO, AND USE OF, THE SITE IS AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR DEVICE OR COMPUTER SYSTEM, OR LOSS OF DATA RESULTING THEREFROM.</span></p>
                                <p className="c4"><span className="c1">TO THE MAXIMUM EXTENT PERMITTED BY LAW, NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY DAMAGES ARISING FROM OR RELATED TO THIS AGREEMENT (FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION), WILL AT ALL TIMES BE LIMITED TO A MAXIMUM OF FIFTY US DOLLARS (U.S. $50). THE EXISTENCE OF MORE THAN ONE CLAIM WILL NOT ENLARGE THIS LIMIT. &nbsp;YOU AGREE THAT OUR SUPPLIERS WILL HAVE NO LIABILITY OF ANY KIND ARISING FROM OR RELATING TO THIS AGREEMENT.</span></p>
                                <p className="c4"><span className="c1">SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU. &nbsp; &nbsp; &nbsp; &nbsp;</span></p>
                                <p className="c4"><strong><span className="c3">General</span></strong></p>
                                <ol className="c6 lst-kix_lm7l5nyh8aix-0 start" start="1">
                                    <li className="c4 c7"><span className="c1">These Terms are subject to occasional revision, and if we make any substantial changes, we may notify you by sending you an e-mail to the last e-mail address you provided to us (if any), and/or by prominently posting notice of the changes on our Site. &nbsp;You are responsible for providing us with your most current e-mail address. In the event that the last e-mail address that you have provided us is not valid, or for any reason is not capable of delivering to you the notice described above, our dispatch of the e-mail containing such notice will nonetheless constitute effective notice of the changes described in the notice. &nbsp;Any changes to these Terms will be effective upon the earlier of thirty (30) calendar days following our dispatch of an e-mail notice to you (if applicable) or thirty (30) calendar days following our posting of notice of the changes on our Site. These changes will be effective immediately for new users of our Site. Continued use of our Site following notice of such changes shall indicate your acknowledgement of such changes and agreement to be bound by the terms and conditions of such changes.</span></li>
                                    <li className="c4 c7"><span className="c1">Electronic Communications. The communications between you and Company use electronic means, whether you use the Site or send us emails, or whether Company posts notices on the Site or communicates with you via email. For contractual purposes, you (a) consent to receive communications from Company in an electronic form; and (b) agree that all terms and conditions, agreements, notices, disclosures, and other communications that Company provides to you electronically satisfy any legal requirement that such communications would satisfy if it were be in a hardcopy writing. The foregoing does not affect your non-waivable rights.</span></li>
                                    <li>Data Processing Agreement. The <a href="https://singleclickshare.com/data-processing-agreement/">Data Processing Agreement</a> is available as part of these Terms for GDPR compliance.</li>
                                    <li className="c4 c7"><span className="c1">Entire Terms. These Terms constitute the entire agreement between you and us regarding the use of the Site. Our failure to exercise or enforce any right or provision of these Terms shall not operate as a waiver of such right or provision. The section titles in these Terms are for convenience only and have no legal or contractual effect. The word “including” means “including without limitation”. If any provision of these Terms is, for any reason, held to be invalid or unenforceable, the other provisions of these Terms will be unimpaired and the invalid or unenforceable provision will be deemed modified so that it is valid and enforceable to the maximum extent permitted by law. &nbsp;Your relationship to Company is that of an independent contractor, and neither party is an agent or partner of the other. These Terms, and your rights and obligations herein, may not be assigned, subcontracted, delegated, or otherwise transferred by you without Company’s prior written consent, and any attempted assignment, subcontract, delegation, or transfer in violation of the foregoing will be null and void. Company may freely assign these Terms. The terms and conditions set forth in these Terms shall be binding upon assignees.</span></li>
                                    <li className="c4 c7"><span className="c1">Copyright/Trademark Information. Copyright © SingleClickShare. All rights reserved. &nbsp;All trademarks, logos and service marks (“Marks”) displayed on the Site are our property or the property of other third parties. You are not permitted to use these Marks without our prior written consent or the consent of such third party which may own the Marks.</span></li>
                                </ol>
                                <p className="c4"><strong><span className="c1">Contact Information</span></strong></p>
                                <p className="c4"><span className="c1">SingleClickShare<br/>
                                4th Floor, Nariman Point<br/>
                                Indore, MP 452010</span></p>
                                    <p className="c4"><span className="c1">Email: contact@singleclickshare.com</span></p>
                                    <p className="c4"><span className="c1">&nbsp;</span></p>
                                </div>
                                </p>
                            </div>
                    </div>
            </Layout>
        </>
    )
}

export default Terms