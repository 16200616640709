import React from "react";
import Layout from "../layouts";

const Services = () => {

    return (
        <>
            <Layout>
                <div className="pricing-header row p-3 pb-md-4 mx-auto mb-5">
                    <div className="col-12 mx-auto text-left mt-1">
                        <p className="fs-3">Service Level Agreement</p>
                        <p className="fs-6 text-muted">
                            <div class="fl-rich-text">
                                
                                <p>This SingleClickShare Service Level Agreement (the “SLA”) applies to Enterprise Plan customers (the “Customers”) of the SingleClickShare Services. This SLA is incorporated into, and forms part of, the <a href="https://singleclickshare.com/terms/">Terms of Use</a>. Capitalized terms not defined in this SLA have the meanings given to them in the Terms of Use.</p>
                                <p><strong>Definitions</strong></p>
                                <p>“Services” means the SingleClickShare services including the SingleClickShare Site, SingleClickShare API, and the related documentation.</p>
                                <p>“Availability” means the following calculation: (Total minutes in the month - Total minutes in month unavailable) / (Total minute in the month). The minutes unavailable excludes scheduled maintenance windows, unavailability caused by acts of the Customer or its agents, network unavailability outside the SingleClickShare network including Third Party Social Services, hacks or other forms of attacks to the Platform, telecommunications provider or Internet failures, and other events outside of SingleClickShare’s control.</p>
                                <p>“Third Party Social Services” means the APIs provided by Facebook, Instagram, Twitter, LinkedIn, Pinterest, YouTube, Google, TikTok, Reddit, Telegram and other social media providers accessible via the Services.</p>
                                <p><strong>Services Availability</strong></p>
                                <p>The SingleClickShare Services shall use commercially reasonable efforts to maintain Availability of 99.9% during each calendar month.</p>
                                <p><strong>Scheduled Maintenance</strong></p>
                                <p>SingleClickShare makes every effort to minimize impact for Customers using the Services. Where reasonably possible, SingleClickShare will provide at least 24 hours notice to the Customer of scheduled maintenance in excess of 30 minutes.</p>
                                <p><strong>Right to Terminate</strong></p>
                                <p>If SingleClickShare fails to meet the Services Availability target for two consecutive calendar months or for any three calendar months within any twelve month period, then the Customer may terminate the Services and receive a prorated refund of any pre-paid fees.</p>
                                <p><strong>Customer Support</strong></p>
                                <p>SingleClickShare will provide the Customer with support from the hours of 9am to 5pm Eastern Standard Time. Support will be provided via the online documentation portal, online chat, email, voice, or video as needed.</p>
                                <p>&nbsp;</p>
                            </div>


                        </p>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Services