import React, { useState } from "react";
import Pricing from "../pages/pricing";
import { Navigate, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { loginFormValidation, createAccountValidation } from "../utils/validation";
import * as userapi from "../api/userapi";

import { Formik, Field, Form, ErrorMessage } from "formik";
import axios from "axios";


const Header = () => {
  const [loginmodalOpen, setLoginmodalOpen] = useState(false);
  //const [disable, setDisable] = useState(false);
  const[signupForm, setSignupForm] = useState(false);

  const navigator = useNavigate();
  const PricingTab = () => {
    navigator("/pricing");
  };
  const openLoginModal = () => {
    console.log("loginmodalOpen", loginmodalOpen);
    
    if(localStorage.getItem("authToken")){
      navigator("/dashboard");
    }
    else{
      setLoginmodalOpen(true);
    }
    
  };
  
  const openSigninForm = () => {    
    setSignupForm(false);
  };
  const openSignupForm = () => {    
    setSignupForm(true);
  };

  const loginpopup = () => {
    setLoginmodalOpen(false);
  };

  const initialState = {
    name:"",
    email: "",
    password: "",
  };
  
  const loginform = async (values, { setSubmitting }) => {
    
    try {
      // Make an API request to check user on the server
            
      const jsonData = JSON.stringify(values);      
      
      const response = await axios({
        method: 'post', //you can set what request you want to be
        url: `${process.env.REACT_APP_BE_API}/api/login`,
        data: values,
        headers: {
          "Content-Type": "application/json",
         // Authorization: localStorage.getItem("authToken"),
        }
      })
      
      console.log("Server response:", response.data);
      if (response.data && response.data.status) {
              localStorage.setItem("authToken", response.data.token);
              localStorage.setItem("__uid", response.data.data.userid);
              localStorage.setItem("email", response.data.data.email);              
              localStorage.setItem("name", response.data.data.name); 
              navigator("/dashboard");
      
      }

      // Reset form and state
      setSubmitting(false);
    } catch (error) {
      // Handle API request errors (e.g., display an error message)
      console.error("API request error:", error);
      setSubmitting(false);
    }
  };

  const createAccount = async (values, { setSubmitting }) => {
    
    try {
      // Make an API request to check user on the server
            
      const jsonData = JSON.stringify(values);
            
      const response = await axios({
        method: 'post', //you can set what request you want to be
        url: `${process.env.REACT_APP_BE_API}/api/users`,
        data: values,
        headers: {
          "Content-Type": "application/json",
         // Authorization: localStorage.getItem("authToken"),
        }
      })
      
      console.log("Server response:", response.data);
      if (response.data && response.data.status) {
              localStorage.setItem("authToken", response.data.token);
              localStorage.setItem("__uid", response.data.data.userid);
              localStorage.setItem("email", response.data.data.email);
              localStorage.setItem("name", response.data.data.name);              
              
              navigator("/dashboard");
      
      }

      // Reset form and state
      setSubmitting(false);
    } catch (error) {
      // Handle API request errors (e.g., display an error message)
      console.error("API request error:", error);
      setSubmitting(false);
    }
  };
  return (
    <>
      <Modal
        isOpen={loginmodalOpen}
        ariaHideApp={false}
        onRequestClose={loginpopup}
        contentLabel="Login Modal"
        portalClassName="react-modal"
        overlayClassName="modal"
        shouldCloseOnOverlayClick={false}
        className="logmodel-popup"
      >
        <main class="form-signin">
          <div class="sign-logo">
            <img
              class=""
              src="/assets/images/single-click-share-logo.png"
              alt=""
              width="100%"
            />
          </div>
         { (signupForm)?
         <>
          <Formik
            initialValues={initialState}
            validationSchema={createAccountValidation}
            onSubmit={createAccount}
          >
            <Form class="signin-form">
              <h1 className="h4 mb-4 fw-normal text-white text-center mt-1">
                Sign-Up to your account
              </h1>
              {/* Form fields and validation errors go here */}
              <div className="mb-3">
                <Field
                  type="text"
                  name="name"
                  placeholder="name"
                  className="form-control"
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="login_error"
                />
              </div>
              <div className="mb-3">
                <Field
                  type="text"
                  name="email"
                  placeholder="Email"
                  className="form-control"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="login_error"
                />
              </div>
              <div className="mb-3">
                <Field
                  type="password"
                  name="password"
                  placeholder="Password"
                  className="form-control"
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="login_error"
                />
              </div>

              <button type="submit" className="btn btn-primary full-width">
                Submit
              </button>
            </Form>
          </Formik>
          <div className="all-btns">
          <div className="forgot-pass mb-3 float-left">
          <button type="button" class="forgot-links" onClick={openSigninForm} >Sign In to Your Account</button>
          </div>
          <div className="forgot-pass mb-3 float-right">
          <button type="button" class="forgot-links">Forgot Password</button>
          </div>
          </div>
          </>
          : <>
          <Formik
            initialValues={initialState}
            validationSchema={loginFormValidation}
            onSubmit={loginform}
          >
            <Form class="signin-form">
              <h1 className="h4 mb-4 fw-normal text-white text-center mt-1">
                Sign-In to your account
              </h1>
              {/* Form fields and validation errors go here */}
              
              <div className="mb-3">
                <Field
                  type="text"
                  name="email"
                  placeholder="Email"
                  className="form-control"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="login_error"
                />
              </div>
              <div className="mb-3">
                <Field
                  type="password"
                  name="password"
                  placeholder="Password"
                  className="form-control"
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="login_error"
                />
              </div>

              <button type="submit" className="btn btn-primary full-width">
                Submit
              </button>
            </Form>
          </Formik>
          <div className="all-btns">
          <div className="forgot-pass mb-3 float-left">
          <button type="button" class="forgot-links" onClick={openSignupForm}>Create Your Account</button>
          </div>

          <div className="forgot-pass mb-3 float-right">
          <button type="button" class="forgot-links">Forgot Password</button>
          </div>
          </div>
          </>}
          <div className="term-condition mb-3">
            <p>
              <a href="/terms">Terms</a> & <a href="/privacy">Privacy Policy</a>
            </p>
            <p>
              Need help? <a href="/pricing#contactpricing">Contact Us</a>
            </p>
          </div>
        </main>
        <button type="button" onClick={loginpopup} className="btn-s-one fill popup-close-icon">
        <i class="bi bi-x-circle"></i>
        </button>
      </Modal>
      <nav className="navbar navbar-expand-lg navbar-light header-bg main-header">
        <div className="container">
          <a href="/" className="navbar-brand">
            <img width="80%" src="assets/images/single-click-share-logo.png" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="bi bi-list"></i>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link active-link" href="/">
                  Home
                </a>
              </li>
              {/* <li className="nav-item">
            <a className="nav-link" href="#">Features</a>
          </li> */}
              <li className="nav-item">
                <a className="nav-link" href="/pricing">
                  Pricing
                </a>
              </li>
              {/* <li className="nav-item">
            <a className="nav-link" href="#">Business Plan</a>
          </li> */}
              <li className="nav-item">
                <a className="nav-link" href="#" onClick={openLoginModal}>
                  {(localStorage.getItem("authToken"))? "Dashboard":"Log In"}
                </a>
              </li>
            </ul>
            <div className="col-md-2 text-end">
              <button
                type="button"
                className="btn light-bray-BG me-2"
                onClick={PricingTab}
              >
                Get Started New
              </button>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
