import React from "react";
import Layout from "../layouts";

const Pricing = () => {

    return (
        <>
            <Layout>
                <div className="m-0">
                    <div className="container">
                        <div className="px-4 pt-5">
                            <div className="container py-3">
                                <main>
                                    <h1 className="fw-medium text-center">Pricing</h1>
                                    <div className="col-lg-6 mx-auto text-center">
                                        <h5 className="fw-bold mb-4 pb-5 rich-text">Sign up today for the Ayrshare API and start managing all your users' social media needs.</h5>
                                    </div>
                                    <div className="pricing-header row p-3 pb-md-4 mx-auto shadow-sm rounded-3 border-all mb-5">
                                        <div className="col-9 mx-auto text-left mt-1">
                                            <p className="fs-3">Business Plan Accounts For Platforms</p>
                                            <p className="fs-5 text-muted">If you manage multiple user accounts, then contact us to get pricing details. Starts at $5 per month.</p>
                                        </div>
                                        <div className="col-3 mx-auto mt-5">
                                            <a href="#contactpricing" target="_self" className="btn btn-outline-light btn-lg d-block">Contact Us </a>
                                        </div>
                                    </div>
                                    <div className="row row-cols-1 row-cols-md-3 mb-3 text-center">

                                        <div className="col">
                                            <div className="card mb-4 rounded-3 shadow-sm">
                                                <div className="card-header py-2">
                                                    <h4 className="my-0 fw-normal">Basic</h4>
                                                </div>
                                                <div className="card-body">
                                                    <h1 className="card-title pricing-card-title">Free</h1>
                                                    <ul className="list-unstyled mt-3 mb-4">
                                                        <li>10 users included</li>
                                                        <li>2 GB of storage</li>
                                                        <li>Email support</li>
                                                        <li>Help center access</li>
                                                    </ul>
                                                    <button type="button" className="w-100 btn btn-lg bg-dark text-white">Start Posting For Free</button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col">
                                            <div className="card mb-4 rounded-3 shadow-sm">
                                                <div className="card-header py-2">
                                                    <h4 className="my-0 fw-normal">Premium</h4>
                                                </div>
                                                <div className="card-body">
                                                    <h1 className="card-title pricing-card-title text-secondary">$1 <small className="text-muted fw-light">/month</small></h1>
                                                    <ul className="list-unstyled mt-3 mb-4">
                                                        <li>20 users included</li>
                                                        <li>10 GB of storage</li>
                                                        <li>Priority email support</li>
                                                        <li>Help center access</li>
                                                    </ul>
                                                    <button type="button" className="w-100 btn btn-lg btn-primary-lg text-white">Subscribe $1/mo.</button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col">
                                            <div className="card mb-4 rounded-3 shadow-sm">
                                                <div className="card-header py-2">
                                                    <h4 className="my-0 fw-normal">Business</h4>
                                                </div>
                                                <div className="card-body">
                                                    <h1 className="card-title pricing-card-title">Contact Us</h1>
                                                    <ul className="list-unstyled mt-3 mb-4">
                                                        <li>30 users included</li>
                                                        <li>15 GB of storage</li>
                                                        <li>Phone and email support</li>
                                                        <li>Help center access</li>
                                                    </ul>
                                                    <a href="#contactpricing" target="_self" className="btn btn-outline-light btn-lg d-block">Contact us</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-6">
                                        <h1 className="fw-medium mb-5 text-center">Frequently Asked Questions</h1>

                                        <div className="pricing-header row p-3 pb-md-4 mx-auto shadow-sm rounded-3 border-all mb-5">
                                            <div className="col-12 mx-auto text-left mt-1">
                                                <p className="fs-3">Do you offer multi-user or agency plans?</p>
                                                <p className="fs-5 text-muted">We offer discounted plans to agencies, platforms, and many types of businesses who have multiple users.</p>
                                            </div>
                                        </div>
                                        <div className="pricing-header row p-3 pb-md-4 mx-auto shadow-sm rounded-3 border-all mb-5">
                                            <div className="col-12 mx-auto text-left mt-1">
                                                <p className="fs-3">What payment methods do you accept?</p>
                                                <p className="fs-5 text-muted">You can pay with any major credit or debit card. Payments are handled securely by Stripe.</p>
                                            </div>
                                        </div>
                                        <div className="pricing-header row p-3 pb-md-4 mx-auto shadow-sm rounded-3 border-all mb-5">
                                            <div className="col-12 mx-auto text-left mt-1">
                                                <p className="fs-3">Can I cancel at any time?</p>
                                                <p className="fs-5 text-muted">Yes you can cancel at any time. You are not locked into a long term commitment. Click the cancel button in the web dashboard or send us an email and we will cancel your account with no questions asked.</p>
                                            </div>
                                        </div>
                                        <div className="pricing-header row p-3 pb-md-4 mx-auto shadow-sm rounded-3 border-all mb-5">
                                            <div className="col-12 mx-auto text-left mt-1">
                                                <p className="fs-3">How many social networks can I connect with the Premium plan?</p>
                                                <p className="fs-5 text-muted">Each Premium account allows you to have one connection to each of the 10 available social networks. This means 1 Facebook, 1 Twitter, 1 Instagram, 1 TikTok, etc. One API post call can send a post to multiple social networks. For example, you can make one call to the /post endpoint and specify the post should go to YouTube, TikTok, and Instagram. This counts as a single post.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="bd-example mb-4" id="contactpricing">
                                        <p className="fs-3">Contact Us For Business Plan Details</p>
                                        <form>
                                            <div className="row">
                                                <div className="col-6">
                                                    <label for="exampleInputEmail1" className="form-label">Your name</label>
                                                    <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                                </div>
                                                <div className="col-6">
                                                    <label for="exampleInputEmail1" className="form-label">Business email address</label>
                                                    <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <label for="exampleInputEmail1" className="form-label">Business name</label>
                                                    <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                                </div>
                                                <div className="col-6">
                                                    <label for="exampleInputEmail1" className="form-label">Business website</label>
                                                    <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="mb-3 col-12">
                                                    <label for="exampleInputEmail1" className="form-label">Please tell us about your business and it's social media needs</label>
                                                    <textarea className="form-control" aria-label="With textarea"></textarea>
                                                </div>
                                                {/* <div className="mb-3 mb-12">
                                                    <label for="exampleInputEmail1" className="form-label">How did you find us?</label>
                                                    <select className="form-select" aria-label=".form-select-sm example">
                                                        <option selected>Open this select menu</option>
                                                        <option value="1">One</option>
                                                        <option value="2">Two</option>
                                                        <option value="3">Three</option>
                                                    </select>
                                                </div> */}
                                            </div>
                                            <button type="submit" className="btn btn-primary">Submit</button>
                                        </form>
                                    </div>
                                </main>

                            </div>
                        </div>

                    </div>
                </div>

                <div className="footertop-banner light-bray-BG">
    <div className="container">
        <div className="row align-items-md-stretch pb-3">
            <div className="col-md-5">
              <div className="h-100 p-5 p-l-0 ml-2 text-right points-img">
                <img width="40%" src="assets/images/points.png" />
              </div>
            </div>
            <div className="col-md-7">
              <div className="h-100 p-5 p-l-0">
                <h3 className="pb-1">It's easy to get started. Start posting today!</h3>
                <p className="fs-5 pb-4">Social media APIs to help you achieve your goals.</p>
                <a href="#" className="btn btn-primary-lg btn-lg" type="button">Get Start Now</a>
              </div>
            </div>
        </div>
    </div>
</div>
            </Layout>
        </>
    )
}

export default Pricing;