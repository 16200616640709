import React from 'react';
import './deleteModal.css'; // Create this file for styling

const Modal = ({ show, handleClose, handleConfirm }) => {
  return (
    <div className={`modal ${show ? 'show' : ''}`} onClick={handleClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <h3>Are you sure you want to delete your account?</h3>
        <div className="modal-actions">
          <button onClick={handleConfirm}>Yes</button>
          <button onClick={handleClose}>No</button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
