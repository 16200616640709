import React, { useEffect, useState } from "react";

import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import DeleteModal from './deleteModal';

const LeftPanel = (props) => {
  const navigator = useNavigate();
  const { active_tab, currentItem, setCurrentItem } = props;
  const [showModal, setShowModal] = useState(false);

  const handleDelete = async () => {
    // Add your delete logic here
    console.log('Record deleted');
    try {
     
      const data = {
        "userid":localStorage.getItem("__uid")
      }   
      
      const response = await axios({
        method: 'delete', //you can set what request you want to be
        url: `${process.env.REACT_APP_BE_API}/api/closeaccount`,
        data: data,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("authToken"),
        }
      })
      
      console.log("Server response:", response.data);
      if (response.data && response.data.status) {
        localStorage.removeItem("authToken");
        localStorage.removeItem("email");
        localStorage.removeItem("__uid");   
        localStorage.removeItem("name");
        setShowModal(false);
        navigator("/");
      
      }
      
    } catch (error) {
      // Handle API request errors (e.g., display an error message)
      console.error("API request error:", error);      
    }
    
  };

  const useremail = localStorage.getItem("email")
    ? localStorage.getItem("email")
    : null;
  const firstLastName = localStorage.getItem("name");
  const [letpanelOptions, setLeftpanelOptions] = useState([
    {
      name: "User Profile",
      key: "userprofile",
    },
    {
      name: "Posts",
      key: "post",
    },
    {
      name: "Social Account",
      key: "socialaccount",
    },
    {
      name: "Accounts",
      key: "account",
    },
  ]);
  // Function to get initials
const getInitials = (name) =>{
  const nameParts = name.split(' ');
  if (nameParts.length >= 2) {
    const firstInitial = nameParts[0].charAt(0);
    const lastInitial = nameParts[1].charAt(0);
    return `${firstInitial}${lastInitial}`.toUpperCase();
  }
  else if (nameParts.length >= 1) {
    const firstInitial = nameParts[0].charAt(0);    
    return `${firstInitial}`.toUpperCase();
  }
  return '';
}
  const onLinkChange = (item) => {
    //setCurrentItem({ name: item.name, key: item.key });
    if (currentItem?.key !== item.key) {
      navigator(`/dashboard/${item?.key}`);
    }
  };
  const closeAccount = async () => {
    
    try {
     
      const data = {
        "userid":localStorage.getItem("__uid")
      }   
      
      const response = await axios({
        method: 'delete', //you can set what request you want to be
        url: `${process.env.REACT_APP_BE_API}/api/closeaccount`,
        data: data,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("authToken"),
        }
      })
      
      console.log("Server response:", response.data);
      if (response.data && response.data.status) {
        localStorage.removeItem("authToken");
        localStorage.removeItem("email");
        localStorage.removeItem("__uid");   
        localStorage.removeItem("name");
        navigator("/");
      
      }
      
    } catch (error) {
      // Handle API request errors (e.g., display an error message)
      console.error("API request error:", error);      
    }
  };
  return (
    <>
      <DeleteModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        handleConfirm={handleDelete}
      />
      <nav
        id="sidebarMenu"
        class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse"
      >
        <div class="user-profile">
          <span class="act-user-prof-text">Active User Profile</span>
          <div class="user-details-col dispaly-flex">
            <span class="user-avtar">{getInitials(firstLastName)}</span>
            <div class="user-avtar-col">
              <h5 class="avtar-hed">Primary Profile</h5>
              <p class="avtar-subhed">{useremail}</p>
            </div>
          </div>
        </div>
        <div class="position-sticky pt-3">
          <ul class="nav flex-column">
            {letpanelOptions.map((item, index) => {
              return (
                <li class="nav-item sidebarMenu">
                  <Link
                    to={`/dashboard/${item?.key}`}
                    onClick={() => {
                      onLinkChange(item);
                    }}
                    className={
                      active_tab === item.key
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    {item.item}
                    {item.name}
                  </Link>
                </li>
              );
            })}
            {/* <li class="nav-item sidebarMenu">
              <a class="nav-link active" aria-current="page" href="#">
                <i class="bi bi-person-vcard"></i>
                <span data-feather="home"></span>
                User Profile
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">
                <i class="bi bi-send"></i>
                <span data-feather="file"></span>
                Posts
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">
                <i class="bi bi-share-fill"></i>
                Social Accounts
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">
                <i class="bi bi-person-square"></i>
                Accounts
              </a>
            </li> */}
            <li class="nav-item">
              <a class="nav-link" href="#">
                <span data-feather="bar-chart-2"></span>
                Reports
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">
                <span data-feather="layers"></span>
                Integrations
              </a>
            </li>
          </ul>

          <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
            <span>Saved reports</span>
            <a class="link-secondary" href="#" aria-label="Add a new report">
              <span data-feather="plus-circle"></span>
            </a>
          </h6>
          <ul class="nav flex-column mb-2">
            <li class="nav-item">
              <a class="nav-link" href="#">
                <span data-feather="file-text"></span>
                Current month
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">
                <span data-feather="file-text"></span>
                Last quarter
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">
                <span data-feather="file-text"></span>
                Social engagement
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">
                <span data-feather="file-text"></span>
                Year-end sale
              </a>
            </li>
          </ul>
          <ul class="nav flex-column mb-2">
            <li><a class="nav-link" href="#" onClick={() => setShowModal(true)}>
                <span data-feather="file-text"></span>
                Close Account
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default LeftPanel;
