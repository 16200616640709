import React from "react";
import Layout from "../layouts";

const Privacy = () => {

    return (
        <>
            <Layout>
                <div className="pricing-header row p-3 pb-md-4 mx-auto mb-5">
                    <div className="col-12 mx-auto text-left mt-1">
                        <p className="fs-3">Privacy Policy</p>
                        <p className="fs-6 text-muted">
                            <div className="fl-rich-text">
                               
                                <p className="p1"><b>Privacy Policy</b></p>
                                <p className="p2">This privacy policy (“Policy”) describes how Single Click Share (“Single Click Share”, “us”, “we” or “our”) and its related companies (“Company”) collect, use and share personal information of consumer users of this website, <a href="https://singleclickshare.com"><span className="s1">singleclickshare.com</span></a><span className="Apple-converted-space">&nbsp; </span>and the mobile apps in the Apple App Store and the Google Play Store (the “Apps”).<span className="Apple-converted-space">&nbsp;</span></p>
                                <p className="p3">When registering for SingleClickShare you can be certain that we have implemented comprehensive security practices to ensure that only registered users and those people who are provided authorized access to, can see your SingleClickShare data. We respect your right to privacy and feel it is important for you to know how we handle the information we receive from you via our website (the “Site”) and app , our support platform, and tools and services offered on the Site (the "Service"). The use of information collected through the Service shall be limited to the purpose of providing the Service for which the client has engaged SingleClickShare.</p>
                                <p className="p4"><b>1. Compliance</b></p>
                                <p className="p3">SingleClickShare complies with the GDPR and its data transfer rules. SingleClickShare relies on appropriate safeguards provided in Article 46 of the GDPR. In the absence of an adequacy decision, a controller or processor may transfer personal data to a third country or an international organization only if the controller or processor has provided appropriate safeguards, and on condition that enforceable data subject rights and effective legal remedies for data subjects are available.</p>
                                <p>SingleClickShare uses model contract clauses or standard data protection clauses for the transfer of personal data to third countries which do not ensure an adequate level of data protection, as described in Article 46 of the GDPR or in some cases alternative transfer solutions described in Article 49 of the GDPR.</p>
                                <p className="p4"><b>2. Information You Provide</b></p>
                                <p className="p2">When registering with an email address, we collect your full name, profile photo and email address.<span className="Apple-converted-space">&nbsp; </span>SingleClickShare uses a third-party intermediary to manage credit card processing. This intermediary is not permitted to store, retain, or use your billing information except for the sole purpose of credit card processing on SingleClickShare’s behalf.</p>
                                <p className="p3">The information you provide is used as follows:</p>
                                <ul className="ul1">
                                    <li className="li2">To operate, maintain, and improve our sites, products, and services.</li>
                                    <li className="li2">To respond to comments and questions and provide customer service.</li>
                                    <li className="li2">To send information including confirmations, invoices, technical notices, updates, security alerts, and support and administrative messages.</li>
                                    <li className="li2">To communicate about promotions, upcoming events, and other news about products and services offered by us and our selected partners after consent is expressly given.</li>
                                    <li className="li3">To provide and deliver products and services customers request.</li>
                                </ul>
                                <p>If your personal information changes, or if you no longer desire our service, you can modify, update, delete your account information at any time by contacting us at the contact information listed below. We will respond to any requests for access to personal information within 30 days. SingleClickShare’s practice is not to use such personal information for marketing purposes unless explicitly authorized. SingleClickShare may use the collected personal information and other information SingleClickShare collects about your use of the Service, to operate and make the Service available to you, for billing, identification, and authentication, to contact you about your use of the Service, research purposes and to generally improve the content and functionality of the Service and Site. On occasion we may send out email newsletters, if you wish to opt out of these newsletters, please follow the unsubscribe directions in these emails.</p>
                                <p>SingleClickShare will retain your information for as long as your account is active or as needed to provide you services. We will also retain personal data we process on behalf of our clients for as long as needed to provide services to our client. We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.</p>
                                <p>SingleClickShare may also transmit or share your personal information with its third party vendors and hosting partners to provide the necessary hardware, software, networking, storage, and other technology and services required to operate and maintain the Service, which may require that your personal information be transferred from your current location to the offices and servers of SingleClickShare and the authorized third parties referred to here. These companies are authorized to use your personal information only as necessary to provide these services to us. Although SingleClickShare owns the software, code, databases, and all rights to the SingleClickShare application, you retain all rights to your data.</p>
                                <p className="p4"><b>3. Information Related to Data Collected for our Clients</b></p>
                                <p className="p3">SingleClickShare collects information under the direction of its clients.<br />
                                    Choice – We collect information for our clients, if you are a visitor or customer of one of our Clients and would no longer like to be included in the record of our Clients that use our service, please contact the client that you interact with directly. If you are a Client and would like to update your account please contact us at the contact information listed below.</p>
                                <p>Service Provider, Sub-Processors/Onward Transfer – SingleClickShare may transfer generic account information to companies that help us provide our service. Transfers to subsequent third parties are covered by the provisions in this Policy regarding notice and choice and the service agreements with our Clients.</p>
                                <p>Access to Data Controlled by our Clients – SingleClickShare has no direct relationship with the devices whose data it processes. Anyone who seeks access, or who seeks to correct, amend, or delete inaccurate data should direct his query to the SingleClickShare client (the account controller). If the client requests SingleClickShare to remove the data, we will respond to their request within 30 business days.</p>
                                <p className="p4"><b>4. Cookies and Tracking Technologies</b></p>
                                <p className="p3">Technologies such as: cookies, beacons, tags, and scripts are used by SingleClickShare and our partners, marketing partners, affiliates, or analytics or service providers, or online customer support provider. These technologies are used in analyzing trends, administering the site, tracking users’ movements around client sites and to gather demographic information about client user base as a whole. We may receive reports based on the use of these technologies by these companies on an individual as well as aggregated basis.</p>
                                <p>We may use cookies to remember users’ settings. Users can control the use of cookies at the individual browser level. If you reject cookies, you may still use our site, but your ability to use some features or areas of our site may be limited</p>
                                <p className="p4"><b>5. Analytics / Log Files</b></p>
                                <p className="p3">As is true with most websites, we gather certain information automatically and store it in log files. This information includes internet protocol (IP) addresses, browser type, , referring/exit pages, operating system, date/time stamp, and clickstream data.</p>
                                <p>We use this information, which does not identify individual users, to analyze trends, to administer the site, to track users’ movements around the site and to gather demographic information about our user base as a whole. We do not link this automatically-collected data to personal information.</p>
                                <p className="p4"><b>6. Local Storage</b></p>
                                <p className="p3">We use Local Storage (LS) such as HTML5 to store content information and preferences. Third parties with whom we partner to provide certain features on our site or to display advertising based upon your Web browsing activity use LS such as HTML5 to collect and store information. Various browsers may offer their own management tools for removing HTML5 LS.</p>
                                <p className="p4"><b>7. Behavioral Advertising / Re-Targeting</b></p>
                                <p className="p3">We partner with a third party to either display advertising on our Web site or to manage our advertising on other sites. Our third party partner may use technologies such as cookies to gather information about your activities on this site and other sites in order to provide you advertising based upon your browsing activities and interests. If you wish to not have this information used for the purpose of serving you interest-based ads, you may opt-out by contacting us at the contact information listed below. Please note this does not opt you out of being served ads. You will continue to receive generic ads.</p>
                                <p className="p4"><b>8. Information Sharing</b></p>
                                <p className="p3">Except as described in this policy, SingleClickShare will not give, sell, rent, share or loan any personal information to any third party. We may disclose such information to respond to subpoenas, court orders, or legal process (including requests to meet national security or law enforcement requirements), or to establish or exercise our legal rights or defend against legal claims.</p>
                                <p>We may also share such information if we believe it is necessary in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of our Terms of Service, or as otherwise required by law. SingleClickShare may also provide non-personal, summary or group statistics about our customers, sales, traffic patterns, and related Site information to reputable third-party vendors, but these statistics will include no personal information.</p>
                                <p className="p4"><b>9. Children’s Personal Information</b></p>
                                <p className="p3">The Service and Site are not intended for, nor does SingleClickShare knowingly collect any personal information from children under the age of 18.</p>
                                <p className="p4"><b>10. Protection of Information</b></p>
                                <p className="p3">The security of your personal information and our clients’ information is important to us. SingleClickShare maintains reasonable security measures to protect your information from loss, destruction, misuse, unauthorized access or disclosure. When you enter sensitive information, such as a credit card number, on our order forms and login credentials on our platform login page, we encrypt the transmission of that information using secure socket layer technology (SSL). These technologies help ensure that your data is safe, secure, and only available to you and to those you provided authorized access. However, no data transmission over the Internet or information storage technology can be guaranteed to be 100% secure due to the nature of the distributed network that is the Internet. Therefore, we cannot guarantee its absolute security. If you have any questions about security on our Website, you can contact us at the contact information listed below.</p>
                                <p className="p4"><b>11. Links to 3rd Party Sites</b></p>
                                <p className="p2">Our site includes links to other websites whose privacy practices may differ from those of SingleClickShare. If you submit personal information to any of those sites, your information is governed by their privacy policies. We encourage you to carefully read the privacy policy of any website you visit.</p>
                                <p className="p6"><span className="s3"><b>12. </b></span><b>Your rights</b></p>
                                <p className="p7">Regarding this data processing, you have the right to request access to your personal data. You have the right to obtain from SingleClickShare the confirmation that it processes your personal data, as well as information regarding the specifics of the processing, such as the purposes of the processing, the categories of personal data concerned, the recipients to whom personal data will be disclosed, the envisaged period for which the personal data will be stored, if possible, etc., as well as to request rectification (modification) or deletion of the data or restricting the processing, you have the right of erasure (‘right to be forgotten’), the right to object or oppose the processing within the limits and conditions provided by law, as well as the right to data portability (you have the right to request us to supply the personal data in a structured form, frequently used and which can be automatically read, for example, in Excel) and to be informed in case of a data breach, when necessary. Also, if you choose to offer your consent, you have the right to withdraw it at any moment, without affecting the processing carried out by SingleClickShare based on the consent expressed by you before you withdrawal of your consent. For any additional questions regarding the manner in which personal data is processed and for exercising the rights mentioned above, please contact contact@singleclickshare.com<span className="Apple-converted-space">&nbsp;</span></p>
                                <p className="p4"><b>13. Social Media Authorization</b></p>
                                <p className="p3">When you authorize a social media network (e.g. Facebook, Twitter, etc.) it may store cookies or other data. Your interactions with the authorization feature are governed by the privacy policy of the authorized social media network and SingleClickShare shall not be held liable for any unlawful data processing activities performed. One platform that you can choose to link is Youtube which is accessible via the YouTube API. By connecting your account to YouTube, you agree to the <a href="https://policies.google.com/privacy">Google Privacy Policy.</a> At any time you can revoke the Apps access to your Google account by unlinking via the Apps or via the <a href="https://myaccount.google.com/permissions">Google security settings page</a>.</p>
                                <p className="p3"><span className="s3"><b>14. Testimonial</b></span></p>
                                <p className="p3">We display personal and company testimonials of satisfied customers on our site in addition to other endorsements. With your consent we may post your testimonial along with your name. If you wish to update or delete your testimonial, you can contact us at the contact information listed below.</p>
                                <p className="p4"><b>15. Changes to Privacy Policy</b></p>
                                <p className="p3">SingleClickShare may update this policy from time to time. You can review the most current version of this privacy policy at any time at <a href="https://singleclickshare.com/privacy/"><span className="s1">https://singleclickshare.com/privacy/</span></a>. Your continued use of the Site or Service constitutes your agreement to be bound by such changes to the privacy policy. Your only remedy, if you do not accept the terms of this privacy policy, is to discontinue use of the Site and Service. If we make any material changes we will notify you or our client by email sent to the e-mail address on your account or by means of a notice on the Site prior to the change becoming effective. We will also update the revision date at the top of this privacy policy document.</p>
                                <p className="p4"><b>16. Business Transactions</b></p>
                                <p className="p3">SingleClickShare may assign or transfer this privacy policy, and your user account and related information and data, to any person or entity that acquires or is merged with SingleClickShare, you will be notified via email and/or a prominent notice on our website of any change in ownership or uses of your personal information, as well as any choices you may have regarding your personal information.</p>
                                <p className="p4"><b>17. Terms of Service</b></p>
                                <p className="p3">When you access and use the Service, you are subject and bound to the SingleClickShare Terms of Service.</p>
                                <p className="p4"><b>18. Contact Us</b></p>
                                <p className="p2">If you have questions regarding this privacy policy or about the security practices of SingleClickShare, please contact us at contact@singleclickshare.com.</p>
                                <p>&nbsp;</p>
                            </div>
                        </p>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Privacy