import React from 'react';
import PropTypes from "prop-types";
import Header from "./header";
import Footer from './footer';
const Layout = ({ children }) => {
    return (
        <>
            <Header />
            <div>{children}</div>
            <Footer /> 

        </>
    )
};

Layout.propTypes = {
    children: PropTypes.element.isRequired
};

export default Layout;