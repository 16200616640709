import React from "react";
import Layout from "../layouts";

const Home =  () => {

    return(
        <>        
        <Layout>
        <div className="bg-dark position-relative fl-node">
  <div className="container">
    <div className="row align-items-md-stretch pb-3">
      <div className="col-md-6">
        <div className="h-100 p-5 text-white rounded-3 p-l-0">
            <h2 className="pb-4">Social Media APIs</h2>
            <p className="fs-4 pb-4">Powerful APIs that enable you to send social media posts effortlessly. For developers and businesses of all sizes.</p>
            <button className="btn btn-outline-light btn-lg" type="button">Get Started Now</button>
        </div>
      </div>
      <div className="col-md-6">
        <div className="h-100 pt-3">
            <img width="100%" src="assets/images/faded-code-4DPSP.png" />
        </div>
      </div>
    </div>
    
    <div className="container">
      <div className="row social-media-icon pb-5 pt-5">
      <div className="col-1">
        <i className="bi-twitter social-icon"></i>
      </div>
      <div className="col-1">
        <i className="bi bi-twitter-x social-icon"></i>
      </div>
      <div className="col-1">
        <i className="bi-facebook social-icon"></i>
      </div>
      <div className="col-1">
        <i className="bi-youtube social-icon"></i>
      </div>
      <div className="col-1">
        <i className="bi-telegram social-icon"></i>
      </div>
      <div className="col-1">
        <i className="bi-skype social-icon"></i>
      </div>
      <div className="col-1">
        <i className="bi-share-fill social-icon"></i>
      </div>
      <div className="col-1">
        <i className="bi-reddit social-icon"></i>
      </div>
      <div className="col-1">
        <i className="bi-pinterest social-icon"></i>
      </div>
      <div className="col-1">
        <i className="bi-instagram social-icon"></i>
      </div>
      <div className="col-1">
        <i className="bi-google social-icon"></i>
      </div>
      <div className="col-1">
        <i className="bi-linkedin social-icon"></i>
      </div>
    </div>
    </div>
  </div>
</div>
  
<div className="row light-bray-BG m-0">
  <div className="container">
    <div className="px-4 pt-5 text-center border-bottom">
      <h1 className="fw-medium pb-5">Send Posts From Your Platform with an API</h1>
      <div className="col-lg-6 mx-auto">
        <h5 className="fw-bold mb-4 pb-5 rich-text">Post to either your company's or your users' social media accounts with a few lines of code.</h5>
      </div>
      <div className="youtube-video">
        <div className="container px-5">
          <img src="assets/images/banner.jpg" className="img-fluid border rounded-3 shadow-lg mb-4" alt="Example" width="700" height="500" loading="lazy" />
        </div>
      </div>
      <div className="youtube-video-text pt-5">
        <ul className="text-left">
          <li className="mb-5"> <i className="bi bi-arrow-right-circle-fill text-secondary"></i> <span className="rich-text">Send real-time or scheduled posts to your social media accounts from your platform.</span></li>
          <li className="mb-5"> <i className="bi bi-arrow-right-circle-fill text-secondary"></i> <span className="rich-text">Post any content including text, images, or videos to one or multiple social networks.</span></li>
          <li className="mb-5"> <i className="bi bi-arrow-right-circle-fill text-secondary"></i> <span className="rich-text">Get up and running in minutes with a few lines of code with easy to use examples.</span></li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div className="container">
  <div className="row pb-5 pt-5 m-0">
    <div className="col-md-6">
      <div className="text-center pl-8 social-net-imgCol">
        <img className="social-net-img" src="assets/images/10-social-networks-1.jpg" alt="Example" width="100%" />
      </div>
    </div>
    <div className="col-md-6">
      <div className="h-100 pt-2 pb-2 pr-8 text-left social-net-txtCol">
        <h2 className="pb-3">Powerful Social Media API Capabilities</h2>
        <h5 className="rich-text">Post to Facebook, X (formerlyTwitter), Instagram, LinkedIn, Reddit, Telegram, TikTok, Google Business Profile (formerly Google My Business), Pinterest, and YouTube.</h5>
      </div>
    </div>
  </div>
</div>

<div className="light-bray-BG m-0 pb-5">
  <div className="container">
    <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
      <h1 className="fw-normal">Why Companies Choose SingleClickShare</h1>
      <p className="fs-5 text-muted">Leverage SingleClickShare to ensure that you do not have to deal with the complexity of managing multiple social network integrations.</p>
    </div>
  </div>

  <div className="container px-4" id="featured-3">
    <div className="row g-4 row-cols-1 row-cols-lg-3 m-0">
      <div className="feature">
        <div className="col card rounded-3 shadow-sm pl-2 pr-2">
          <div className="fs-xxx-larg pb-3">
            <i className="bi bi-check-circle-fill text-secondary"></i>
          </div>
          <h4 className="pricing-card-title pb-3">Get to market quickly with a lower build cost</h4>
          <h5 className="rich-text pb-3">Avoid spinning up a dedicated development team. No need to spend time on complex approval and permission processes, and you do not have to pay the social networks for API access.</h5>
        </div>
      </div>
      <div className="feature">
        <div className="col card rounded-3 shadow-sm pl-2 pr-2">
          <div className="fs-xxx-larg pb-3">
            <i className="bi bi-check-circle-fill text-secondary"></i>
          </div>
          <h4 className="pricing-card-title pb-3">Get to market quickly with a lower build cost</h4>
          <h5 className="rich-text pb-3">Avoid spinning up a dedicated development team. No need to spend time on complex approval and permission processes, and you do not have to pay the social networks for API access.</h5>
        </div>
      </div>
      <div className="feature">
        <div className="col card rounded-3 shadow-sm pl-2 pr-2">
          <div className="fs-xxx-larg pb-3">
            <i className="bi bi-check-circle-fill text-secondary"></i>
          </div>
          <h4 className="pricing-card-title pb-3">Get to market quickly with a lower build cost</h4>
          <h5 className="rich-text pb-3">Avoid spinning up a dedicated development team. No need to spend time on complex approval and permission processes, and you do not have to pay the social networks for API access.</h5>
        </div>
      </div>
    </div>
    <p className="text-center pt-5">
      <a href="#" className="btn btn-primary btn-lg">Get Started Now</a>
    </p>
  </div>
</div>

<div className="m-0 pt-5">
  <div className="container">
    <div className="pricing-header p-3 pb-md-4 mx-auto text-center bg-light rounded-3">
      <p className="fs-5 text-muted">Programmatically publish to your social media networks using your language of choice including:</p>
      <p className="fs-5 text-muted pb-4">Node.js, JavaScript, PHP, Python, C#, Go, Java, and Ruby on Rails.</p>
      <span className="pb-4 tech-img"><img width="100%" src="assets/images/tech-logos-grey-843hhr.png" /></span>
      <p className="fs-5 text-muted">Detailed and comprehensive <a href="#">API documentation</a>, with code examples for all the Social API endpoints.</p>
    </div>
  </div>
  <div className="container px-4 py-5">
    <div className="row g-4 row-cols-1 row-cols-lg-3">
      <div className="feature col mb-6">
        <div className="feature-icon pb-4">
          <i className="bi bi-send api-cons"></i>
        </div>
        <h5 className="pb-3">Send Posts</h5>
        <h5 className="text-muted">Create a post with text, images, or videos and send it immediately or schedule it for a future date or time.</h5>
      </div>
      <div className="feature col mb-6">
        <div className="feature-icon pb-4">
          <i className="bi bi-send api-cons"></i>
        </div>
        <h5 className="pb-3">Delete Posts</h5>
        <h5 className="text-muted">Simply send the post ID to the delete endpoint to delete your post from all of your social media networks.</h5>
      </div>
      <div className="feature col mb-6">
        <div className="feature-icon pb-4">
          <i className="bi bi-send api-cons"></i>
        </div>
        <h5 className="pb-3">Post History</h5>
        <h5 className="text-muted">Get history and status of the posts you sent via SingleClickShare, with detailed metadata for each post.</h5>
      </div>
      <div className="feature col mb-6">
        <div className="feature-icon pb-4">
          <i className="bi bi-send api-cons"></i>
        </div>
        <h5 className="pb-3">Post History</h5>
        <h5 className="text-muted">Get history and status of the posts you sent via SingleClickShare, with detailed metadata for each post.</h5>
      </div>
      <div className="feature col mb-6">
        <div className="feature-icon pb-4">
          <i className="bi bi-send api-cons"></i>
        </div>
        <h5 className="pb-3">Post History</h5>
        <h5 className="text-muted">Get history and status of the posts you sent via SingleClickShare, with detailed metadata for each post.</h5>
      </div>
      <div className="feature col mb-6">
        <div className="feature-icon pb-4">
          <i className="bi bi-send api-cons"></i>
        </div>
        <h5 className="pb-3">Post History</h5>
        <h5 className="text-muted">Get history and status of the posts you sent via SingleClickShare, with detailed metadata for each post.</h5>
      </div>
      <div className="feature col mb-6">
        <div className="feature-icon pb-4">
          <i className="bi bi-send api-cons"></i>
        </div>
        <h5 className="pb-3">Post History</h5>
        <h5 className="text-muted">Get history and status of the posts you sent via SingleClickShare, with detailed metadata for each post.</h5>
      </div>
      <div className="feature col mb-6">
        <div className="feature-icon pb-4">
          <i className="bi bi-send api-cons"></i>
        </div>
        <h5 className="pb-3">Post History</h5>
        <h5 className="text-muted">Get history and status of the posts you sent via SingleClickShare, with detailed metadata for each post.</h5>
      </div>
      <div className="feature col mb-6">
        <div className="feature-icon pb-4">
          <i className="bi bi-send api-cons"></i>
        </div>
        <h5 className="pb-3">Post History</h5>
        <h5 className="text-muted">Get history and status of the posts you sent via SingleClickShare, with detailed metadata for each post.</h5>
      </div>
    </div>
    <p className="text-center pb-3">
      <a href="#" className="btn btn-outline-light btn-lg">Get Started Now</a>
    </p>
    <p className="fs-5 text-muted text-center">Still not sure? <a href="#">Read why you shouldn't use SingleClickShare.</a></p>
  </div>
</div>

  <div className="light-bray-BG position-relative fl-node">
    <div className="container">
      <div className="pricing-header p-3 pb-md-4 mx-auto text-center bg-light rounded-3">
        <h1 className="fw-normal">Multiple User Accounts</h1>
      </div>
      <div className="row align-items-md-stretch pb-3">
        <div className="col-md-5">
          <div className="h-100 p-5 rounded-3 p-l-0">
              <h5 className="pb-4">Our <b>Business Plan</b> is perfect for platforms, products, agencies, consulting firms, and any other business that has multiple users. Get all your users posting via your platform with the SingleClickShare social media APIs.</h5>
              <ul className="text-left multiple-user-col mb-3">
                <li className="mb-3"><i className="bi bi-arrow-right-square-fill primary-color"></i> <span className="rich-text">Post on Behalf of Your Users to Their Social Accounts</span></li>
                <li className="mb-3"> <i className="bi bi-arrow-right-square-fill primary-color"></i> <span className="rich-text">Seamlessly Integrate with Your Product or Platform</span></li>
                <li className="mb-3"> <i className="bi bi-arrow-right-square-fill primary-color"></i> <span className="rich-text">Manage Multiple User Profiles via API or Dashboard</span></li>
              </ul>
              <button className="btn btn-primary btn-lg" type="button">Learn More</button>
          </div>
        </div>
        <div className="col-md-7">
          <div className="h-100 pt-3 scs-img">
              <img width="75%" src="assets/images/scs-business-flow-1.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div className="position-relative fl-node">
    <div className="container">
      <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
        <h1 className="fw-normal pb-4">A Great Developer Dashboard</h1>
        <p className="fs-5 text-muted">SingleClickShare has the right tools to enable developers to build amazing apps.</p>
      </div>
      <div className="row align-items-md-stretch pb-3">
        <div className="col-md-6">
          <div className="h-100 p-5 rounded-3 p-l-0 ml-2">
              <ul className="text-left great-Dev-count mb-3">
                <li className="mb-3"><span className="text-secondary"> 1</span> <p className="rich-text m-0">Create posts and see JSON request and response.</p></li>
                <li className="mb-3"><span className="text-secondary"> 2</span> <p className="rich-text m-0">Manage social accounts and user profiles.</p></li>
                <li className="mb-3"><span className="text-secondary"> 3</span> <p className="rich-text m-0">Access API Keys and usage history.</p></li>
                <li className="mb-3"><span className="text-secondary"> 4</span> <p className="rich-text m-0">See the history of all the posts your users sent.</p></li>
              </ul>
          </div>
        </div>
        <div className="col-md-6">
          <div className="h-100 pt-3 dash-img">
              <img width="75%" src="assets/images/banner.jpg" />
          </div>
        </div>
      </div>
    </div>
  </div>
  
    <div className="position-relative fl-node bg-dark">
      <div className="container">
        <div className="pricing-header p-3 pb-md-4 mx-auto text-center text-white">
          <h1 className="fw-normal pb-4">Create a Social Media Post with a Few Lines of Code</h1>
        </div>
        <div className="row align-items-md-stretch pb-3">
          <div className="col-md-7">
            <div className="bd-example">
              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Home</button>
                  <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Profile</button>
                  <button className="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Contact</button>
                </div>
              </nav>
              <div className="tab-content text-white" id="nav-tabContent">
                  <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                    <p><strong>This is some placeholder content the Home tab's associated content.</strong> Clicking another tab will toggle the visibility of this one for the next. The tab JavaScript swaps classes to control the content visibility and styling. You can use it with tabs, pills, and any other <code>.nav</code>-powered navigation.</p>
                  </div>
                  <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                    <p><strong>This is some placeholder content the Profile tab's associated content.</strong> Clicking another tab will toggle the visibility of this one for the next. The tab JavaScript swaps classes to control the content visibility and styling. You can use it with tabs, pills, and any other <code>.nav</code>-powered navigation.</p>
                  </div>
                  <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                    <p><strong>This is some placeholder content the Contact tab's associated content.</strong> Clicking another tab will toggle the visibility of this one for the next. The tab JavaScript swaps classes to control the content visibility and styling. You can use it with tabs, pills, and any other <code>.nav</code>-powered navigation.</p>
                  </div>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="h-100 p-5 p-l-0 text-white">
              <p className="fs-5 pb-1">The SingleClickShare Social Media API handles all the setup and maintenance for the major social media networks.</p>
              <p className="fs-5 pb-4">One API to rule them all. Yeah, we went there!</p>
              <button className="btn btn-outline-light btn-lg" type="button">Read The Doc</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  <div className="position-relative fl-node pt-4">
    <div className="container">
      <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
        <h4 className="fw-normal pb-4"><b>Thousands of businesses</b> have sent <b>millions of social posts</b> via SingleClickShare.</h4>
        <p className="fs-5 text-muted">The world’s largest enterprises and the most ambitious startups use SingleClickShare to manage all their users’ social accounts, grow their revenue, and accelerate new business opportunities.</p>
      </div>
      <div className="row align-items-md-stretch pb-3">
        <div className="col-md-6">
          <div className="h-100 p-5 p-l-0 ml-2 text-center">
            <span className="pb-3 fs-xxx-larg dispaly-block"><i className="bi bi-quote text-secondary"></i></span>
            <p className="fs-5">The SingleClickShare social media API is so much better than all the other options out there. And I love the docs.</p>
            <p className="fs-5"><i>- CTO, SaaS Real Estate Platform</i></p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="h-100 p-5 p-l-0 ml-2 text-center">
            <span className="pb-3 fs-xxx-larg dispaly-block"><i className="bi bi-quote text-secondary"></i></span>
            <p className="fs-5">We saved a ton of time integrating SingleClickShare. It's a no brainer for any product that needs social posting.</p>
            <p className="fs-5"><i>- Head of Product, Digital Asset Platform</i></p>
          </div>
        </div>
      </div>

      <div className="row align-items-md-stretch pb-3">
        <div className="col-md-6">
          <div className="h-100 p-5 p-l-0 ml-2 text-right points-img">
            <img width="40%" src="assets/images/points.png" />
          </div>
        </div>
        <div className="col-md-6">
          <div className="h-100 p-5 p-l-0">
            <h3 className="pb-1">It's easy to get started. Start posting today!</h3>
            <p className="fs-5 pb-4">Social media APIs to help you achieve your goals.</p>
            <button className="btn btn-primary-lg btn-lg" type="button">Get Start Now</button>
          </div>
        </div>
      </div>
    </div>
  </div>
        </Layout>
        </>
    )
}

export default Home