import * as Yup from "yup";

export const loginFormValidation = Yup.object().shape({    
    email: Yup.string().email("Invalid email").required("Please enter email"),
    password: Yup.string().required("Please enter password"),
  });
  export const createAccountValidation = Yup.object().shape({
    name: Yup.string().required("Please enter name"),
    email: Yup.string().email("Invalid email").required("Please enter email"),
    password: Yup.string().required("Please enter password"),
  });