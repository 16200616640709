import React from "react";

const Footer = () => {
    return (
        <>
        <div className="footer-bg">
    <div className="container">
      <footer className="py-5 p-b-0">
        <div className="row">
          <div className="col-6 col-md-2 mb-3">
            
            <ul className="nav flex-column">
              <li className="nav-item mb-2"><a href="/" className="nav-link p-0 text-body-secondary">Home</a></li>              
              <li className="nav-item mb-2"><a href="/pricing" className="nav-link p-0 text-body-secondary">Pricing</a></li>              
              <li className="nav-item mb-2"><a href="/aboutus" className="nav-link p-0 text-body-secondary">About Us</a></li>
            </ul>
          </div>
    
          <div className="col-6 col-md-2 mb-3">
            
            <ul className="nav flex-column">
              <li className="nav-item mb-2"><a href="/privacy" className="nav-link p-0 text-body-secondary">Privacy</a></li>
              <li className="nav-item mb-2"><a href="/terms" className="nav-link p-0 text-body-secondary">Terms</a></li>
              <li className="nav-item mb-2"><a href="/data-processing-agreement" className="nav-link p-0 text-body-secondary">Data Protection Agreement

</a></li>
              <li className="nav-item mb-2"><a href="/service-level-agreement" className="nav-link p-0 text-body-secondary">Service Level Agreement</a></li>
              
            </ul>
          </div>
    
          <div className="col-6 col-md-2 mb-3">
            <p><span className={"text-white"}>Powerful platform that enable you to send social media posts effortlessly. For individuals and businesses of all sizes.</span></p>
            <ul className="nav flex-column">
              <li className="nav-item mb-2"></li>
              
            </ul>
          </div>
    
          {/* <div className="col-md-5 offset-md-1">
            <form>
              <h5 className="text-white">Subscribe to our newsletter</h5>
              <p className="text-white">Monthly digest of what's new and exciting from us.</p>
              <div className="d-flex flex-column flex-sm-row w-100 gap-2">
                <label for="newsletter1" className="visually-hidden">Email address</label>
                <input id="newsletter1" type="text" className="form-control" placeholder="Email address" />
                <button className="btn btn-outline-light" type="button">Subscribe</button>
              </div>
            </form>
          </div> */}
        </div>
    
        <div className="d-flex flex-column flex-sm-row justify-content-between py-4 my-4 border-top m-b-0">
          <p className="text-white">© 2023 SingleClickShare. All rights reserved.</p>
          {/* <ul className="list-unstyled d-flex">
            <li className="ms-3"><a className="link-body-emphasis" href="#"><i className="bi bi-twitter-x social-icon"></i></a></li>
            <li className="ms-3"><a className="link-body-emphasis" href="#"><i className="bi-facebook social-icon"></i></a></li>
            <li className="ms-3"><a className="link-body-emphasis" href="#"><i className="bi-youtube social-icon"></i></a></li>
            <li className="ms-3"><a className="link-body-emphasis" href="#"><i className="bi-instagram social-icon"></i></a></li>
            <li className="ms-3"><a className="link-body-emphasis" href="#"><i className="bi-linkedin social-icon"></i></a></li>
          </ul>   */}
        </div>
      </footer>
    </div>
  </div>
        </>

    )
}

export default Footer;
