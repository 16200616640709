import React from "react";
import Layout from "../layouts2";
import LeftPanel from "./leftPanel";
import { Navigate, useNavigate, useParams } from "react-router-dom";

const Dashboard = (props) => {
  const navigator = useNavigate();
  //const { active_tab } = props;
  let { active_tab } = useParams();
  const isAuthenticated = localStorage.getItem("authToken");
  if (!localStorage.getItem("authToken")) {
    navigator("/");
  }

  return (
    <>
      {isAuthenticated ? (
        <Layout>
          <div class="container-fluid">
            <div class="row">
              <LeftPanel 
                active_tab = {active_tab}
              />

              <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4 pt-3">
                <div class="table-responsive">
                  <div class="accordion" id="accordionExample">
                    {console.log("active_tab", active_tab)}
                    {(active_tab === "userprofile" || active_tab === undefined || active_tab == null)  ? (
                      <div class="accordion-item mb-4">
                        <h4 class="accordion-header" id="headingOne">
                          <button
                            class="accordion-button"
                            type="button"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            <h3>Create a user Profile</h3>
                          </button>
                        </h4>
                        <div
                          id="collapseOne"
                          class="show"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            <h5>
                              Use the <a href="#">Profile API</a> for more
                              advanced features
                            </h5>
                          </div>

                          <div class="row container-full pt-4 mb-3">
                            <div class="col-9">
                              <h4 class="padd-left-22">All Profiles</h4>
                              <h6 class="padd-left-22">
                                Total User Profiles: <span>121</span>
                              </h6>
                            </div>
                            <div class="col-3">
                              <button
                                type="button"
                                class="btn btn-outline-primary setting-btn"
                              >
                                <i class="bi bi-gear-fill"></i>
                                Setting
                              </button>
                              <form class="float-right">
                                <input
                                  type="search"
                                  class="form-control form-control-dark"
                                  placeholder="Search..."
                                  aria-label="Search"
                                />
                              </form>
                            </div>
                          </div>

                          <div class="admin-tabs mb-3">
                            <nav>
                              <div
                                class="nav nav-tabs mb-4"
                                id="nav-tab"
                                role="tablist"
                              >
                                <button
                                  class="nav-link active"
                                  id="nav-home-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#nav-home"
                                  type="button"
                                  role="tab"
                                  aria-controls="nav-home"
                                  aria-selected="true"
                                >
                                  All Profiles
                                </button>
                                <button
                                  class="nav-link"
                                  id="nav-profile-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#nav-profile"
                                  type="button"
                                  role="tab"
                                  aria-controls="nav-profile"
                                  aria-selected="false"
                                >
                                  User Profiles
                                </button>
                                <button
                                  class="nav-link"
                                  id="nav-contact-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#nav-contact"
                                  type="button"
                                  role="tab"
                                  aria-controls="nav-contact"
                                  aria-selected="false"
                                >
                                  Team Members
                                </button>
                              </div>
                            </nav>
                            <div class="tab-content" id="nav-tabContent">
                              <div
                                class="tab-pane fade show active"
                                id="nav-home"
                                role="tabpanel"
                                aria-labelledby="nav-home-tab"
                              >
                                <div class="row">
                                  <h5 class="col-9">All Profile</h5>
                                  <div class="col-3 text-right">
                                    <button class="badge-btn light-blue-bg ">
                                      {" "}
                                      <i class="bi bi-lightning-charge"></i>{" "}
                                      Active Profile
                                    </button>
                                    <button class="badge-btn light-green-bg">
                                      {" "}
                                      <i class="bi bi-star"></i> Primary
                                    </button>
                                  </div>
                                  <p class="mt-4 font-bold-gray">
                                    This is some placeholder content the Profile
                                    tab's associated content.
                                  </p>
                                  <p class="font-bold-gray">
                                    This is some placeholder content the Profile
                                    tab's associated content.{" "}
                                    <a href="#">
                                      <i class="bi bi-copy"></i>
                                    </a>
                                  </p>
                                </div>
                              </div>
                              <div
                                class="tab-pane fade"
                                id="nav-profile"
                                role="tabpanel"
                                aria-labelledby="nav-profile-tab"
                              >
                                <div class="row">
                                  <h5 class="col-9">User Profile</h5>
                                  <div class="col-3 text-right">
                                    <button class="badge-btn light-blue-bg ">
                                      {" "}
                                      <i class="bi bi-lightning-charge"></i>{" "}
                                      Active Profile
                                    </button>
                                    <button class="badge-btn light-green-bg">
                                      {" "}
                                      <i class="bi bi-star"></i> Primary
                                    </button>
                                  </div>
                                  <p class="mt-4 font-bold-gray">
                                    This is some placeholder content the Profile
                                    tab's associated content.
                                  </p>
                                  <p class="font-bold-gray">
                                    This is some placeholder content the Profile
                                    tab's associated content.{" "}
                                    <a href="#">
                                      <i class="bi bi-copy"></i>
                                    </a>
                                  </p>
                                </div>
                              </div>
                              <div
                                class="tab-pane fade"
                                id="nav-contact"
                                role="tabpanel"
                                aria-labelledby="nav-contact-tab"
                              >
                                <div class="row">
                                  <h5 class="col-9">Team Members</h5>
                                  <div class="col-3 text-right">
                                    <button class="badge-btn light-blue-bg ">
                                      {" "}
                                      <i class="bi bi-lightning-charge"></i>{" "}
                                      Active Profile
                                    </button>
                                    <button class="badge-btn light-green-bg">
                                      {" "}
                                      <i class="bi bi-star"></i> Primary
                                    </button>
                                  </div>
                                  <p class="mt-4 font-bold-gray">
                                    This is some placeholder content the Profile
                                    tab's associated content.
                                  </p>
                                  <p class="font-bold-gray">
                                    This is some placeholder content the Profile
                                    tab's associated content.{" "}
                                    <a href="#">
                                      <i class="bi bi-copy"></i>
                                    </a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : active_tab === "post" ? (
                      <div class="accordion-item mb-4">
                        <h4 class="accordion-header" id="headingTwo">
                          <button
                            class="accordion-button"
                            type="button"                            
                            data-bs-target="#collapseTwo"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            <h3>Send a Post</h3>
                          </button>
                        </h4>
                        <div
                          id="collapseTwo"
                          class="accordion-collapse collapse show"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            <h5>
                              Use the <a href="#">Profile API</a> for more
                              advanced features
                            </h5>
                          </div>

                          <div class="row container-full pt-4 mb-3">
                            <div class="col-6">
                              <div class="input-text">
                                <span class="input-text pb-2 dispaly-block">
                                  Post Text
                                </span>
                                <textarea
                                  placeholder="Enter a Post Text"
                                  class="form-control text-area-h"
                                  aria-label="Enter a Post Text"
                                ></textarea>
                              </div>
                            </div>
                            <div class="col-6">
                              <span class="input-text pb-2 dispaly-block">
                                Add Image or Video
                              </span>
                              <div class="custom-choose-file">
                                <div class="choose-file-with-icon">
                                  <input
                                    type="file"
                                    class=""
                                    aria-label="Small file input example"
                                  />
                                  <i class="bi bi-cloud-upload"></i>
                                  <p>Click to Upload</p>
                                </div>
                                <p class="mb-0">
                                  JPEG, PNG, JPG, GIF, WEBP, MP4, MOV, or AVI up
                                  to 50 MB
                                </p>
                              </div>
                            </div>
                          </div>

                          <div class="container-full pt-4 mb-3">
                            <span class="dispaly-block pb-2">
                              Social Network
                            </span>
                            <div class="row social-icons-networks">
                              <div class="col-3">
                                <div class="mb-3 form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="flexSwitchCheckChecked"
                                    checked
                                  />
                                  <span>
                                    <i class="bi bi-facebook blue-col"></i>{" "}
                                    Facebook pages
                                  </span>
                                </div>
                              </div>
                              <div class="col-3">
                                <div class="mb-3 form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="flexSwitchCheckChecked"
                                    checked
                                  />
                                  <span>
                                    <i class="bi bi-facebook blue-col"></i>{" "}
                                    Facebook pages
                                  </span>
                                </div>
                              </div>
                              <div class="col-3">
                                <div class="mb-3 form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="flexSwitchCheckChecked"
                                    checked
                                  />
                                  <span>
                                    <i class="bi bi-facebook blue-col"></i>{" "}
                                    Facebook pages
                                  </span>
                                </div>
                              </div>
                              <div class="col-3">
                                <div class="mb-3 form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="flexSwitchCheckChecked"
                                    checked
                                  />
                                  <span>
                                    <i class="bi bi-facebook blue-col"></i>{" "}
                                    Facebook pages
                                  </span>
                                </div>
                              </div>
                              <div class="col-3">
                                <div class="mb-3 form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="flexSwitchCheckChecked"
                                    checked
                                  />
                                  <span>
                                    <i class="bi bi-facebook blue-col"></i>{" "}
                                    Facebook pages
                                  </span>
                                </div>
                              </div>
                              <div class="col-3">
                                <div class="mb-3 form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="flexSwitchCheckChecked"
                                    checked
                                  />
                                  <span>
                                    <i class="bi bi-facebook blue-col"></i>{" "}
                                    Facebook pages
                                  </span>
                                </div>
                              </div>
                              <div class="col-3">
                                <div class="mb-3 form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="flexSwitchCheckChecked"
                                    checked
                                  />
                                  <span>
                                    <i class="bi bi-facebook blue-col"></i>{" "}
                                    Facebook pages
                                  </span>
                                </div>
                              </div>
                              <div class="col-3">
                                <div class="mb-3 form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="flexSwitchCheckChecked"
                                    checked
                                  />
                                  <span>
                                    <i class="bi bi-facebook blue-col"></i>{" "}
                                    Facebook pages
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : active_tab == "socialaccount" ? (
                      <div class="accordion-item mb-4">
                        <h4 class="accordion-header" id="headingThree">
                          <button
                            class="accordion-button"
                            type="button"                            
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            <h3>Social accounts for primary profile</h3>
                          </button>
                        </h4>
                        <div
                          id="collapseThree"
                          class="accordion-collapse collapse show"
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            <h5>Click a icon to link a social network </h5>
                          </div>

                          <div class="container-full pt-4 mb-3">
                            <span class="dispaly-block pb-2">
                              Social Network
                            </span>
                            <div class="row link-social-account">
                              <div class="col-3 mb-3 link-account-col active-account">
                                <span class="social-icon">
                                  <i class="bi bi-facebook blue-col"></i>
                                </span>
                                <div class="soc-name-linked">
                                  <strong class="social-account-name">
                                    Facebook
                                  </strong>
                                  <span class="show-linked-btn">Linked</span>
                                </div>
                                <span class="social-avtar-img">
                                  <i class="bi bi-person-circle"></i>
                                </span>
                              </div>
                              <div class="col-3 mb-3 link-account-col disable-account">
                                <span class="social-icon">
                                  <i class="bi bi-instagram insta-color"></i>
                                </span>
                                <div class="soc-name-linked">
                                  <strong class="social-account-name">
                                    Instagram
                                  </strong>
                                  <a class="simple-link" href="#">
                                    Click to Link
                                  </a>
                                </div>
                                <span class="social-avtar-img">
                                  <i class="bi bi-person-circle"></i>
                                </span>
                              </div>
                              <div class="col-3 mb-3 link-account-col active-account">
                                <span class="social-icon">
                                  <i class="bi bi-facebook blue-col"></i>
                                </span>
                                <div class="soc-name-linked">
                                  <strong class="social-account-name">
                                    Facebook
                                  </strong>
                                  <span class="show-linked-btn">Linked</span>
                                </div>
                                <span class="social-avtar-img">
                                  <i class="bi bi-google insta-color"></i>
                                </span>
                              </div>
                              <div class="col-3 mb-3 link-account-col disable-account">
                                <span class="social-icon">
                                  <i class="bi bi-instagram insta-color"></i>
                                </span>
                                <div class="soc-name-linked">
                                  <strong class="social-account-name">
                                    Instagram
                                  </strong>
                                  <a class="simple-link" href="#">
                                    Click to Link
                                  </a>
                                </div>
                                <span class="social-avtar-name">D</span>
                              </div>
                              <div class="col-3 mb-3 link-account-col active-account">
                                <span class="social-icon">
                                  <i class="bi bi-facebook blue-col"></i>
                                </span>
                                <div class="soc-name-linked">
                                  <strong class="social-account-name">
                                    Facebook
                                  </strong>
                                  <span class="show-linked-btn">Linked</span>
                                </div>
                                <span class="social-avtar-name">D</span>
                              </div>
                              <div class="col-3 mb-3 link-account-col disable-account">
                                <span class="social-icon">
                                  <i class="bi bi-instagram insta-color"></i>
                                </span>
                                <div class="soc-name-linked">
                                  <strong class="social-account-name">
                                    Instagram
                                  </strong>
                                  <a class="simple-link" href="#">
                                    Click to Link
                                  </a>
                                </div>
                                <span class="social-avtar-img">
                                  <i class="bi bi-person-circle"></i>
                                </span>
                              </div>
                              <div class="col-3 mb-3 link-account-col active-account">
                                <span class="social-icon">
                                  <i class="bi bi-facebook blue-col"></i>
                                </span>
                                <div class="soc-name-linked">
                                  <strong class="social-account-name">
                                    Facebook
                                  </strong>
                                  <span class="show-linked-btn">Linked</span>
                                </div>
                                <span class="social-avtar-img">
                                  <i class="bi bi-person-circle"></i>
                                </span>
                              </div>
                              <div class="col-3 mb-3 link-account-col disable-account">
                                <span class="social-icon">
                                  <i class="bi bi-instagram insta-color"></i>
                                </span>
                                <div class="soc-name-linked">
                                  <strong class="social-account-name">
                                    Instagram
                                  </strong>
                                  <a class="simple-link" href="#">
                                    Click to Link
                                  </a>
                                </div>
                                <span class="social-avtar-img">
                                  <i class="bi bi-person-circle"></i>
                                </span>
                              </div>
                            </div>
                            <div
                              class="alert alert-warning alert-dismissible fade show"
                              role="alert"
                            >
                              <i class="bi bi-info-circle-fill"></i>
                              If the primary profile is suspended, all other
                              user profiles will be suspended as well. We
                              recommend not using the primary profile as an
                              active User Profile.
                              <button
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="alert"
                                aria-label="Close"
                              ></button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : active_tab == "account" ? (
                      <div class="accordion-item mb-4">
                        <h4 class="accordion-header" id="headingFour">
                          <button
                            class="accordion-button"
                            type="button"                            
                            data-bs-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour"
                          >
                            <h3>Accounts</h3>
                          </button>
                        </h4>
                        <div
                          id="collapseFour"
                          class="accordion-collapse collapse show"
                          aria-labelledby="headingFour"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            <h5>Manage your account information</h5>
                          </div>

                          <div class="container-full pt-4 mb-3">
                            <div class="row mb-2">
                              <div class="col-md-6">
                                <h3 class="blue-color-dark">
                                  Set Company Name{" "}
                                  <i class="bi bi-pencil-square"></i>
                                </h3>
                                <h5 class="font-bold-gray">Company Name</h5>
                              </div>

                              <div class="col-md-6">
                                <h3 class="blue-color-dark">Business Plan</h3>
                                <h5 class="font-bold-gray">
                                  Current Subscription
                                </h5>
                              </div>
                              <div class="col-md-12">
                                <hr />
                              </div>

                              <div class="account-container border rounded mt-3 pt-3 pb-3">
                                <div class="row">
                                  <div class="col-10">
                                    <div class="company-name-col">
                                      <h5>Max Pack</h5>
                                      <button class="company-badge-btn company-badge-active">
                                        Inactive
                                      </button>
                                      <button class="company-badge-btn company-badge-inactive">
                                        Inactive
                                      </button>
                                    </div>
                                    <div class="company-logoImg">
                                      <i class="bi bi-hypnotize"></i>
                                    </div>
                                    <h5 class="font-bold-gray mt-3">
                                      The max pack offers additonal social media
                                      API endpoints and features.
                                    </h5>
                                  </div>
                                  <div class="col-2 pt-3">
                                    <button
                                      type="button"
                                      class="btn btn-outline-success float-right"
                                    >
                                      Learn More{" "}
                                      <i class="bi bi-arrow-right"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>

                              <div class="account-container border rounded mt-3 pt-3 pb-3">
                                <div class="row">
                                  <div class="col-10">
                                    <h5 class="mt-3">
                                      Manage Billing and Views Invoices
                                    </h5>
                                  </div>
                                  <div class="col-2">
                                    <button
                                      type="button"
                                      class="btn btn-outline-primary float-right"
                                    >
                                      Billing <i class="bi bi-arrow-right"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>

                              <div class="account-container border rounded mt-3 pt-3 pb-3">
                                <div class="row">
                                  <div class="col-10">
                                    <h5 class="mt-3">Setup Email Login</h5>
                                    <h6 class="mt-3 font-bold-gray">
                                      Create an email login in addition to your
                                      Google or GitHub login.{" "}
                                    </h6>
                                  </div>
                                  <div class="col-2">
                                    <button
                                      type="button"
                                      class="btn btn-primary float-right"
                                    >
                                      Set Up <i class="bi bi-arrow-right"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </main>
            </div>
          </div>
        </Layout>
      ) : (
        <Navigate to="/" />
      )}
    </>
  );
};

export default Dashboard;
