import React from "react";
import Layout from "../layouts";

const AboutUs = () => {

    return (
        <>
            <Layout>
                <div className="pricing-header row p-3 pb-md-4 mx-auto mb-5">
                    <div className="col-12 mx-auto text-left mt-1">
                        <p className="fs-3">About Us</p>
                        <p className="fs-6 text-muted">
                        <div class="fl-rich-text">
<p>SingleClickShare is the leading social media API company that provides the core infrastructure for social media posting, management, and analytics. With Ayrshare, thousands of businesses now focus on building their product instead of stitching together and maintaining multiple social media platforms.</p>
<p>SingleClickShare was built by developers. Over the years we’ve built many apps and often needed a way to post to multiple social media accounts via an API at a reasonable price. We couldn't find any.</p>
<p>Since we couldn't find a service that met our needs, we decided to build one. Now businesses of all sizes use Ayrshare to post and manage their accounts.</p>
<hr />
<p>Now SingleClickShare is the social media infrastructure platform for businesses and the leader in the MarTech market for social media management APIs. Thousands of companies—from the world’s largest enterprises to the most ambitious startups—use Ayrshare to manage all their users’ social accounts, grow their revenue, and accelerate new business opportunities. Our mission is to increase the adoption of social media management across other platforms, and we have a staggering amount of work ahead. The company is privately held and is headquartered in New York City, NY.</p>
<p>&nbsp;</p>
</div>
                    </p>
                </div>
            </div>
        </Layout >
        </>
    )
}

export default AboutUs